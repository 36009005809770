
          $font-path: "/home/runner/work/spotdif/spotdif/src/assets/fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.main-outer-box {
    height: 100vh;
    display: flex;
    flex-direction: row;
    border-radius: 16px;
    height: calc(100vh - 112px);
    border-top-right-radius: 16px;

    .user-list-outer {
        padding: 16px;
        background: #fff;
        border-top-left-radius: 16px;

        .user-list-inner {
            border-radius: 24px;
            border: 1px solid #dcdbdb;
            align-items: center;

            .search-icon {
                padding: 10px;
            }
        }

        .menu-button {
            display: flex;
            justify-content: center;
        }
    }

    .user-inner-01 {
        overflow: hidden;
        height: calc(100vh - 190px);
        background: $COLOR_WHITE;
        // overflow: auto;
        border-bottom-left-radius: 16px;

        .chat-rooms--list {
            width: 100%;
            margin: 0;
            border-bottom: 1px solid $GRAY_BORDER;

            min-height: 100px;
            height: 100px;
            // max-height: 100px;

            &:hover,
            &.chat-rooms--list-active {
                background: saturate(transparentize($PROVOCATIVE, 0.6), 0.8);
            }

            .user {
                padding: 12px 0 12px 8px;
                display: flex;
                flex-direction: row;
                align-items: center;
                // padding-left: 16px;
                margin: 0;

                .avatar-outer {
                    margin: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    align-self: center;
                    position: relative;

                    .user-profile {
                        height: 50px;
                        margin-right: 16px;
                        width: 50px;
                    }

                    .online-user {
                        position: absolute;
                        top: 40px;
                        right: 21px;
                    }

                    .online-user span {
                        height: 12px;
                        width: 12px;
                        border-radius: 100%;
                        background-color: $COLOR_GREEN-SHADE_2;
                    }
                }

                .room-name span {
                    line-height: 1.1;
                }
            }
        }

        .chat-list-wrapper-virtualized {
            overflow: none !important;
        }
    }
}

.last-createdAt {
    display: flex;
    flex-direction: column;

    .latest-message-with-badge {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .unread-count-badge {
            margin-right: 10px;
        }
    }
}

// chat header
.header-box {
    border-top-right-radius: 16px;

    .chat-header {
        border-top-right-radius: 16px;
        height: 78px;
        background-color: $COLOR_WHITE;

        .chat-user-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;
            padding-left: 16px;
            border-right: 2px solid $GRAY_BORDER;
            @media screen and (max-width: 768px) {
                font-size: 1rem;
            }

            &.hide-right-border {
                border-right: none;
            }

            .avatar-outer {
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                align-self: center;
                position: relative;

                .user-profile {
                    height: 50px;
                    margin-right: 16px;
                    width: 50px;
                }

                .online-user {
                    position: absolute;
                    top: 30px;
                    right: 11px;
                }

                .online-user span {
                    height: 12px;
                    width: 12px;
                    border-radius: 100%;
                    background-color: $COLOR_GREEN-SHADE_2;
                }
            }

            .user-name {
                color: $COLOR_BG;
                font-weight: 700;
                @media screen and (max-width: 768px) {
                    font-size: 0.9rem;
                    padding-left: 0.1rem;
                }
            }

            .muted-notifications {
                color: $AEROSPACE_ORANGE;
                border: 1px solid $AEROSPACE_ORANGE;
                background-color: $SUGAR_MILK;
                padding: 0px 4px;
                display: flex;
                gap: 4px;
                align-items: center;
                border-radius: 8px;
                font-size: 14px;
            }

            .lead-details-btn {
                border: 2px solid $COLOR_BLUE-SHADE_1;
                padding: 0px 10px;
                height: 20px;
                border-radius: 50px;
                color: $COLOR_BLUE-SHADE_1;
                @media screen and (max-width: 768px) {
                    padding: 0;
                    height: 15px;
                    min-width: 97px;
                    font-size: 11px;
                }
            }
        }

        .chat-user-info-mobile {
            display: flex;
            flex-direction: column;
            gap: 0px;
            align-items: baseline;
            padding-left: 0px;
            border-right: none;
            margin-left: 24px;
        }

        .rating-cnt {
            display: flex;
            // align-items: center;
            flex-direction: column;
            justify-content: center;
            border-right: 2px solid $GRAY_BORDER;
            padding: 0px 16px;

            .rate-text {
                font-size: 14px;
                color: $COLOR_BG;
                font-weight: 700;
            }

            .rating {
                font-size: 14px;
                color: $COLOR_BG;

                .rating-icon {
                    color: $GRAY_BORDER;
                    font-size: 20px !important;
                }

                .select-lead {
                    color: $COLOR_BG;
                    text-transform: math-auto;
                    font-weight: 400;
                    padding: 0;
                }
            }
        }

        .menu-option {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .chat-header-mobile {
        height: 4.5rem;
        align-items: center;
        // .lead-details{
        .lead-details-btn {
            border: 2px solid $COLOR_BLUE-SHADE_1;
            padding: 0px 10px;
            height: 20px;
            border-radius: 50px;
            color: $COLOR_BLUE-SHADE_1;
        }

        @media screen and (max-width: 768px) {
            flex-wrap: nowrap;
        }

        // }
    }
}

.header-box-mobile {
    align-items: center;

    .inbox-arrow-back {
        height: 35px;
        width: 35px;
        margin-left: 14px;
    }

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: start;
        min-height: 125px;
    }
}

.rating-cnt {
    .contact-status {
        .rate-text {
            font-size: 16px;
            padding: 0px 16px;
        }

        .rating {
            .select-lead {
                padding: 0px 16px;
                color: $GRAY-1 !important;
            }
        }
    }
}

.rating-cnt-mobile {
    .rate-text {
        padding: 0px 24px;
        font-size: 16px;
        @media screen and (max-width: 768px) {
            padding: 0px 20px;
        }
    }

    .rating {
        padding: 0px 25px;
        @media screen and (max-width: 768px) {
            padding: 0px 20px;
        }

        .rating-icon-mobile {
            color: $GRAY_BORDER;
            font-size: 20px !important;
        }
    }
}

//threads chatbox
.threads-outer-cnt {
    padding: 16px;
    overflow: auto;
    display: flex;
    justify-content: center;
    overflow: hidden;

    .day-title {
        color: $BLACK_OAK;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }
}

.inbox-body {
    position: relative;

    .file-dropper {
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 10px;
        transform: translate(-50%, -50%);
        border: 2px dashed #cfbdbd;
        text-align: center;
        background: #fff;

    }

    .upload-photos {
        height: 100px;
        width: 100%;
        background-color: #dbd3d375;
        position: absolute;
        bottom: 0;
        border: 3px solid #ff4e00;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px;
        justify-content: flex-start;


        .uploaded-pics {
            width: 75px;
            height: 75px;
            border-radius: 5px;
            border: 2px solid #ff4e00;
            margin-top: 5px;
        }

        .add-img {
            position: relative;

            svg {
                width: 100%;
                height: 100%;
                color: #ff4e00;
                cursor: pointer;
            }
        }

        .upload-photos .add-img {
            flex-grow: 1;
        }

        .remove-img {
            position: relative;

            svg {
                cursor: pointer;
                position: absolute;
                right: -10px;
                width: 24px;
                height: 24px;
                color: #ff4e00;
                background-color: #00002f;
                border-radius: 50%;
                z-index: 2;
            }
        }

    }
}

.message-outgoing {
    padding: 10px;
    text-align: left;
    max-width: fit-content;
    display: flex;
    justify-content: flex-end;
    border-radius: 14px;

    p {
        font-weight: 500;
        color: $COLOR_BG;
        font-size: 16px;
    }
}

.message-incoming {
    padding: 10px;
    text-align: right;
    display: flex;
    justify-content: flex-start;
    max-width: fit-content;

    p {
        font-weight: 500;
        color: $COLOR_BG;
        font-size: 16px;
    }
}

.message-text {
    border-radius: 14px;
    padding: 8px;
    text-align: left;
    word-wrap: break-word;

    &.message-bg-outgoing {
        background-color: $LAVENDER_SYRUP;
        color: $COLOR_BG;
    }

    &.message-bg-incoming {
        background: $COLOR_WHITE;

        p {
            font-weight: 500;
            color: $COLOR_BG;
            font-size: 16px;
        }
    }
}

.lightbox-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.lightbox-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 500px;
    padding: 10px 2px 10px 20px;
    background-color: white;
    border-bottom: 1px solid #ccc;
}

.lightbox-header h1 {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
}

.modalCloseWrapper {
    display: flex;
    align-items: center;
}

.modalCloseBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.image-wrapper {
    width: 500px;
    max-height: 500px;
    object-fit: contain;
    background-color: white !important;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}

.chat-thread-item {
    &.paper-message {
        max-width: 55% !important;
    }

    &.media {
        // TODO: ensure we don't keep using important
        width: 200px !important;
    }

    .box-media {
        padding: 4px;
        border-radius: 4px;
        height: 200px;
        width: 200px;
        object-fit: cover;

        &:hover {
            cursor: pointer;
            background-color: transparentize($COLOR_BG, 0.5);
        }
    }

}


.paper-message p,
.message-sender .css-3su884-MuiPaper-root {
    border-radius: 6px !important;
}

.message-time-outgoing {
    padding-left: 16px;
    font-size: 12px !important;
    color: $COLOR_BG;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.message-time-incoming {
    padding-left: 16px;
    font-size: 12px !important;
    color: $COLOR_BG;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.message-form {
    position: relative;
    align-items: center;
    padding: 0px 10px;
    background: #fff;
    border-bottom-right-radius: 16px;

    .gallery-icon {
        height: 26px;
        width: 26px;
        color: $PROVOCATIVE;
        cursor: pointer;
        transform: rotate(320deg);
    }
}

// send message
.message-textfield input {
    background-color: $COLOR_WHITE;
    border: none;
    border-bottom: 0;
    // border-bottom: 1px solid #ccc;
    /* Initial bottom border */
    outline: none;
    /* Remove default focus outline */
    box-shadow: unset !important;
    border-bottom-right-radius: 16px;
    height: 1.1375em;
}

.message-textfield .MuiFilledInput-root:before,
.message-textfield .MuiFilledInput-root:after {
    content: unset !important;
}

.message-textfield div {
    border-bottom-right-radius: 16px;
}

.message-textfield:focus,
.message-textfield:hover {
    border-bottom: none;
    /* Remove bottom border on hover and focus */
}

/* scrollbar */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 255, 255, 0.3);
}

.text-wrap {
    text-wrap: wrap !important;
}

.no-convo-selected {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
}

.block-one {
    width: 100%;
    height: auto;
    background: #f8f8f8;
    padding: 15px;
    border-radius: 8px;
}

.action-block-main {
    border-radius: 0.5rem;
    border: 1px solid $COLOR_BG_LIGHT;
    padding: 12px;

    .action-block-notes {
        border: 1px solid $BORDER_COLOR;
        padding: 0.5rem;
        border-radius: 0.5rem;
    }

    .lead-sale {
        margin-top: 0.5rem;
    }

    .contract.lead-sale {
        .lead-sale--container {
            align-items: flex-start;
            min-height: 92px;

            .lead-sale--status {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 1px solid $CARD-WRAPPER-DARK;
                border-radius: 32px;
                padding: 0.5rem;
                padding-left: 1rem;
                margin: 4px 0;

                p {
                    font-size: 0.9rem;
                    line-height: 1.5rem;
                    height: 1.7rem;
                }
            }
        }
    }
}

.autocomplete-field-chat-room {
    & .spotdif-field {
        width: 100% !important;
    }
}

.autocomplete-field.contact-options {
    width: 175px;
    height: 1.2rem;

    & .spotdif-field {
        .spotdif-outlined-field--input {
            background: none !important;
        }

        width: 100% !important;

        fieldset {
            border: none !important;

            margin-top: 0;
            margin-bottom: 1rem;
        }
    }

    @media screen and (max-width: 768px) {
        width: 110px;
    }
}

.show-disabled:hover {
    cursor: not-allowed;
}

.contact-status-label {
    font-size: 1rem;
    padding-top: 0.5rem;
    font-weight: 600;
    @media screen and (max-width: 768px) {
        padding-top: 0rem;
        font-size: 0.9rem;
    }
}

.report-reason-main {
    border-radius: 0.5rem;
    border: 1px solid $COLOR_BG_LIGHT;
    padding: 12px;
    text-wrap: wrap;

    p {
        color: #79798f;
        font-size: 1rem;
        font-weight: 400;
        margin-top: 0.25rem;
    }

    .spotdif-field {
        width: 100% !important;
    }
}

.block-right-header-main {
    margin-top: 1rem;
}

.add-notes-heading {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
}

.report-lead-heading {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
}

.chat-room-details-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.block-one-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;

    .sub-heading {
        font-size: 1rem;
        font-weight: normal;
    }

    .section-one {
        display: flex;
        width: 90%;
        justify-content: space-between;
        margin: auto;
        color: $COLOR_LABEL;

        div {
            color: $COLOR_LABEL;

            p {
                color: black;
            }
        }

        .lead-id {
            text-align: right;
        }

        .sid {
            text-align: right;
        }

        .Report-Accepted-v2 {
            background: #54acf91a;
            color: #54acf9;
        }

        .Valid-v2 {
            background: #cdf4e3;
            color: #1de993;
        }

        .Report-Rejected-v2 {
            background: hsl(0, 50%, 77%);
            color: $COLOR_HEADING_V2;
        }

        .pause-v2 {
            background: #f1e6cf;
            color: #ffa800;
        }

        .Reported-v2 {
            background: #ecedd2;
            color: #d8dc21;
        }

        .Archived-v2 {
            background: #e9e2e2;
            color: gray;
        }

        .archived-leads {
            opacity: 0.4;
        }
    }

    .section-two {
        margin-top: 1rem !important;
        display: flex;
        width: 90%;
        justify-content: space-between;
        margin: auto;
        color: $COLOR_LABEL;

        div {
            color: $COLOR_LABEL;

            p {
                color: black;
            }
        }
    }

    .section-three {
        margin-top: 1rem !important;
        width: 90%;
        margin: auto;
        color: $COLOR_LABEL;

        div {
            color: $COLOR_LABEL;

            p {
                color: black;
            }
        }
    }
}

.contact-main {
    width: 25%;
    @media screen and (max-width: 768px) {
        width: 40%;
    }
}

.rate-lead-action-container {
    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
}

.contact-options {
    .spotdif-field {
        div {
            div {
                padding: 0px 12px;
            }
        }
    }
}

// New added responsive css --S

@media (min-width: 320px) and (max-width: 767px) {
    .header-box-mobile .inbox-arrow-back {
        height: 24px;
        width: 32px;
        margin-left: 0;
    }
    .header-box .chat-header .chat-user-info-mobile {
        margin-left: 15px;
    }
    .rating-cnt-mobile .rating {
        padding: 0px 10px;
    }
    .rating-cnt-mobile .rate-text {
        padding: 0px 10px;
    }
    .contact-main {
        width: 49%;
    }
    .rating-cnt .css-icmola-MuiGrid-root {
        flex-basis: 51%;
        max-width: 50%;
    }
    .header-box-mobile {
        min-height: 115px;
    }
    .header-box .chat-header-mobile {
        padding: 5px;
    }
    .contact-main {
        width: 53%;
    }
    .rate-lead-action-container {
        justify-content: unset;
        width: 100%;
    }

    .contact-main .css-60999s-MuiButtonBase-root-MuiIconButton-root {
        justify-content: end;
    }

    .header-box .chat-header-mobile {
        justify-content: space-between;
    }
    .header-box .chat-header .chat-user-info .user-name {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .main-outer-box .user-list-outer .user-list-inner {
        display: flex;
    }
    .user-list-inner .MuiInputBase-root.MuiInputBase-colorPrimary.css-c5w4o1-MuiInputBase-root {
        font-size: 15px;
    }
    .main-outer-box .user-inner-01 .chat-rooms--list .user {
        padding: 10px !important;
    }
    .main-outer-box .user-list-outer {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }
    .main-outer-box .user-inner-01 {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
    }
    .username span {
        word-wrap: break-word;
    }
    .header-box .chat-header-mobile,
    .header-box-mobile {
        border-top-left-radius: 16px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .main-outer-box .user-list-outer {
        border-top-left-radius: 16px !important;
        border-top-right-radius: 16px !important;
    }
    .header-box .chat-header-mobile,
    .header-box-mobile {
        border-top-left-radius: 16px;
    }
    .header-box-mobile .inbox-arrow-back {
        height: 27px;
        width: 27px;
        margin-left: 14px;
    }

    .header-box .chat-header .chat-user-info-mobile {
        flex-basis: 70%;
        max-width: 70%;
    }

    .contact-main {
        width: 23%;
    }

    .header-box-mobile {
        min-height: 115px;
    }

    .header-box .chat-header-mobile {
        flex-wrap: nowrap;
        padding: 9px 0px;
    }
    .rating-cnt {
        padding: 5px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .username span {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .header-box .chat-header .chat-user-info .user-name {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .main-outer-box .user-list-outer .user-list-inner {
        display: flex;
    }

    .user-list-inner .MuiInputBase-root.MuiInputBase-colorPrimary.css-c5w4o1-MuiInputBase-root {
        font-size: 15px;
    }

    .header-box .chat-header .chat-user-info .user-name {
        font-size: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .header-box .chat-header .chat-user-info {
        gap: 6px;
        padding: 10px !important;
    }

    .header-box .chat-header .rating-cnt {
        padding: 0px 5px;
    }
    .header-box .chat-header .chat-user-info {
        flex-basis: 49.333333%;
        max-width: 49.333333%;
    }

    .contact-main {
        width: 34%;
    }

    .header-box .chat-header .rating-cnt .rating .rating-icon {
        font-size: 18px !important;
    }
    .header-box .chat-header .chat-user-info .avatar-outer {
        min-width: 40px;
    }

    .header-box .chat-header .chat-user-info .lead-details-btn {
        padding: 2px 29px;

    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .username span {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .header-box .chat-header .chat-user-info .lead-details-btn {
        padding: 2px 14px;
        height: 20px;
        font-size: 10px;
    }

    .header-box .chat-header .chat-user-info {
        gap: 5px;
        padding: 10px !important;
    }

    .header-box .chat-header .chat-user-info .user-name {
        font-size: 18px !important;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .header-box .chat-header .chat-user-info .avatar-outer {
        min-width: 40px;
    }

    .header-box .chat-header .rating-cnt {
        padding: 0px;
    }
    .header-box .chat-header .rating-cnt .rating .rating-icon {
        font-size: 16px !important;
    }

    .header-box .chat-header .chat-user-info .lead-details-btn {
        padding: 2px 18px;
        font-size: 10px;
    }

    .header-box .chat-header .chat-user-info {
        gap: 5px;
        padding: 7px !important;
        flex-basis: 46% !important;
        max-width: 46% !important;
    }
    .contact-status-label {
        font-size: 14px;
        margin-left: 10px !important;
    }

    .contact-main {
        width: 36%;
    }
    .header-box .chat-header {
        justify-content: space-between;
    }
    .main-outer-box .user-list-outer .user-list-inner {
        display: flex;
    }
    .user-list-inner .MuiInputBase-root.MuiInputBase-colorPrimary.css-c5w4o1-MuiInputBase-root {
        font-size: 15px;
    }
    .main-outer-box .user-list-outer .user-list-inner .search-icon {
        padding: 7px;
    }
}
