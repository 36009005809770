
          $font-path: "/home/runner/work/spotdif/spotdif/src/assets/fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.card-wrapper-light p {
    font: normal normal 400 15px/18px Euclid Circular A;
    letter-spacing: 0px;
    color: #FFF;
    opacity: 1;
}

.card-wrapper-dark p {
    font: normal normal 400 15px/18px Euclid Circular A;
    letter-spacing: 0px;
    color: #ffffff91;
}
p.price_box {
    letter-spacing: 0px;
    color: #fff;
    font-size: 32px !important;
    font-weight: 500;
    line-height: 20px;
    margin-top: 19px;
}
.price_box_main {
    display: flex;
    align-items: baseline;
    justify-content: center;
}
.light-card,
.dark-card {
    text-align: center;
    .percentage_box {
        color: #ffffff;
        font-size: 32px;
        margin-top: 19px;
    }
    .number_box {
        color: #ffffff;
        font-size: 30px;
        margin-top: 19px;
    }
}
.light-card:nth-child(2){
    margin-left: 25px;
    margin-right: 0;
}
.card-wrapper-light,
.card-wrapper-dark {
    position: relative;
}
.card-wrapper-light::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100px;
    width: 1px;
    background: #0000001a;
    bottom: 0;
}
.card-wrapper-dark::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100px;
    width: 1px;
    background: rgb(255 255 255 / 23%);
    bottom: 0;
}

.dark-card:nth-child(2) {
    padding-left: 69px;
}

// responisve start css

@media (min-width: 1600px) and (max-width: 1899px) {
    .dark-card .percentage_box, .dark-card .number_box {
        margin-top: 16px;
        font-size: 30px;
    }
    .lead-quantity-wrap .card-wrapper-dark {
        padding: 60px 38px 38px 38px;
        align-items: unset;
    }
    .dark-card:nth-child(2) {
        padding-left: 55px;
    }
}


@media (min-width: 1400px) and (max-width: 1599px) {
    .lead-quantity-wrap .card-wrapper-light {
        padding: 30px;
        min-height: 254px;
    }
    .lead-quantity-wrap .card-wrapper-dark,
    .tab-user-content-card {
        min-height: 254px;
    }
    p.price_box {
        font-size: 23px !important;
    }
    .dark-card .percentage_box, .dark-card .number_box {
        margin-top: 18px;
        font-size: 25px;
    }
    .lead-quantity-wrap .card-wrapper-dark {
        padding: 85px 15px 38px 6px;
        align-items: unset;
    }
    .dark-card:nth-child(2) {
        padding-left: 20px;
    }
    .card-wrapper-dark p {
        font: normal normal 400 14px/18px $FONT_PRIMARY;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .lead-quantity-wrap .card-wrapper-light {
        padding: 30px;
        min-height: 255px;
    }
    .lead-quantity-wrap .card-wrapper-dark {
        padding: 20px;
        min-height: 255px;
    }
    .lead-quantity-wrap .lead-quantity-input {
        min-height: 255px;
    }
    p.price_box,
    .percentage_box,
    .number_box {
        font-size: 24px !important;
    }
    .card-wrapper-light p,
    .card-wrapper-dark p {
        font: normal normal 500 15px/17px Euclid Circular A;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .lead-quantity-wrap .card-wrapper-light,
    .lead-quantity-wrap .card-wrapper-dark {
        padding: 20px;
        min-height: 235.74px;
    }
    p.price_box,
    .percentage_box,
    .number_box {
        font-size: 24px !important;
    }
    .card-wrapper-light p,
    .card-wrapper-dark p {
        font: normal normal 500 15px/17px Euclid Circular A;
    }
    .dark-card:nth-child(2) {
        padding-left: 28px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .lead-quantity-wrap .card-wrapper-light,
    .lead-quantity-wrap .card-wrapper-dark {
        width: 100%;
        justify-content: space-around;
    }
}
@media (min-width: 320px) and (max-width: 767px) {
    .lead-quantity-wrap .card-wrapper-light,
    .lead-quantity-wrap .card-wrapper-dark {
        width: 100%;
    }

    .lead-quantity-wrap .card-wrapper-light {
        padding: 20px;
        min-height: 150px;
    }
    .lead-quantity-wrap .card-wrapper-dark {
        padding: 14px;
        min-height: 150px;
    }
    p.price_box,
    .percentage_box,
    .number_box {
        font-size: 20px !important;
    }
    .card-wrapper-light p,
    .card-wrapper-dark p {
        font: normal normal 500 13px/17px Euclid Circular A;
    }
    .dark-card:nth-child(2) {
        padding-left: 35px;
        p {
            max-width: 142px;
        }
    }
     .dark-card:nth-child(1) {
       margin-left: 14px ;
       p {
        max-width: 122px;
       }
    }
}
