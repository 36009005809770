
          $font-path: "/home/runner/work/spotdif/spotdif/src/assets/fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.table-v2 {
    .table-card-block {
        display: flex;
        gap: 32px;
        margin-bottom: 20px;
        justify-content: space-between;

        @media screen and (max-width: 767px) {
            flex-wrap: wrap;
        }
    }

    .table-v2-block {
        padding: 10px;
        background-color: #fff;
        border-radius: 8px;
        // height: 70vh;

        // table columns collapsing css
        th,
        td {
            padding-left: 12px !important;
            padding-right: 12px !important;
        }

        th:first-child,
        td:first-child {
            padding-left: 0px !important;
            // padding-right: 0/px !important;
        }

        & > div {
            height: 70vh;

            overflow-y: auto;

            &::-webkit-scrollbar {
                display: block;
            }
        }

        // overflow-y: auto;

        // &::-webkit-scrollbar {
        //     display: none;
        // }

        table {
            border-collapse: unset;
            margin-top: -10px;

            //     height: 70vh;
            // overflow-y: auto;

            thead {
                th {
                    background: #eaeaf9;
                    padding: 12px 0px;
                    font-size: 16px;
                    font-weight: 500;
                    // text-align: center;

                    &:first-child {
                        border-top-left-radius: 8px;
                    }

                    &:last-child {
                        border-top-right-radius: 8px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        // text-align: center;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 16px;

                        .status_icon {
                            img {
                                width: 21px;
                                height: 21px;
                                border-radius: 0;
                            }

                            .report-rejected {
                                width: 32px;
                                height: 32px;
                            }
                        }

                        &:first-child {
                            border-top-left-radius: 8px;
                            border-bottom-left-radius: 8px;
                        }

                        &:last-child {
                            border-top-right-radius: 8px;
                            border-bottom-right-radius: 8px;
                        }
                    }

                    &:nth-of-type(even) td {
                        background: #f6f6f6;
                        padding: 20px 0;
                    }

                    &:nth-of-type(odd) td {
                        padding: 10px 0;
                    }

                    // tr td{

                    // }
                }
            }
        }
    }
}

.common-table-btn {
    padding: 6px 10px;
    border: 1px solid #142f73;
    border-radius: 20px;
    font-weight: 600;
    color: #142f73;

    &.table-btn-actve,
    &:hover,
    &.active-expand {
        color: #fff;
        background: #142f73;
    }
}

.expanded-row {
    width: 100%;
    background: #fff; // temporary
    display: table-row;

    .expanded-row-block {
        display: flex;
        padding: 5px 30px;
        gap: 15px;

        .expanded-heading {
            font-size: 28px;
            // text-align: left;
            color: $COLOR_PRIMARY;
            font-weight: 600;
        }

        h2.more-details {
            color: $COLOR_BG !important;
        }

        .info-items {
            padding-bottom: 10px;

            .sub-heading {
                color: #79798f;
                font-size: 12px;
                font-weight: 400;
            }

            .sub-value {
                color: #000030;
                font-size: 14px;
                font-weight: 400;
                max-width: 200px;
                overflow-x: auto;
            }
        }

        .badge {
            border-radius: 18px;
        }

        .block-left {
            width: 60%;
            background: #f8f8f8;
            padding: 30px 60px;
            border-radius: 8px;

            .expanded-heading {
                margin-bottom: 22px;
            }

            .info {
                display: flex;

                .info-right,
                .info-left {
                    width: 50%;
                }

                .info-items {
                    .sub-value {
                        padding-bottom: 5px;
                    }
                }
            }
        }

        .block-right {
            width: 40%;
            padding: 30px 30px;
            border: 1px solid $COLOR_BORDER_V2;
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            .block-right-header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 48px;

                .expanded-heading {
                    color: $COLOR_HEADING_V2;
                }

                span {
                    padding: 10px;
                    border-radius: 100%;
                    background: $COLOR_HEADING_V2;
                    color: #fff;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }

            .info-items {
                display: flex;
                justify-content: space-between;

                .sub-heading {
                    font-size: 14px;
                }

                .sub-value {
                    font-size: 16px;
                    color: #79798f;
                }
            }

            .action-box {
                margin-top: 20px;
                display: flex;
                gap: 20px;
            }
        }
    }
}

.badge-v2 {
    font-size: 14px;
    // text-align: center;
    padding: 5px 23px;
    border-radius: 18px;
    text-transform: capitalize;
}

.Report-Accepted-v2 {
    background: #ffede5;
    color: #ff4e00;
}

.Valid-v2 {
    background: #cdf4e3;
    color: #1de993;
}

.Report-Rejected-v2 {
    background: #ffe5ee;
    color: #ee404c;
}

.pause-v2 {
    background: #f1e6cf;
    color: #ffa800;
}

.Reported-v2 {
    background: #f6edfb;
    color: #a94cd3;
}

.Archived-v2 {
    background: #e9e2e2;
    color: gray;
}

.archived-leads {
    opacity: 0.4;
}

.promo {
    display: flex;
    gap: 1em;
}

.expire-link > svg {
    color: #19cbb1;
    cursor: pointer;
}

.trash-icon-red > svg {
    color: #ff0000;
    cursor: pointer;
    margin-top: 0.15rem;
}

.ml-1 {
    margin-left: 1rem;
    cursor: pointer;
}

.DropdownContainer {
    border-radius: 30px;
    border: 2px solid #9fa1b3;
    margin: 1rem 0rem;
    background-color: transparent;
    width: 100%;
}

// .m-3{
//     margin: 0.5rem 3rem
// }

.text-align-left {
    // text-align: left;
}

.ml-3 {
    margin-left: 3rem;
    width: auto;
}

.table-v2-block .promo-table table {
    // background-color: aqua;
}

.table-v2 .table-v2-block .promo-table table thead th {
    text-align: left;
}

.table-v2 .table-v2-block .promo-table table tbody tr td {
    text-align: left;
}

.modal-veritical-scroll {
    max-height: 460px;
    overflow-y: auto;
    width: 100%;
    text-align: left;
}

// just fixes as above css is directly copied
.promolink-v2 {
    .table_header-col {
        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    .d-flex.gap {
        gap: 14px;
    }

    .m-3 {
        margin: 0.5rem 0.5rem;
    }

    .parent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 460px;
    }

    .parent-copy-url {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        max-width: 460px;
    }

    .copy-url {
        display: inline-block;
        max-width: 440px;
        overflow: hidden;
    }
}

.table_col {
    display: flex;

    img {
        height: 21px;
        width: 21px;
    }
}

p.users-count-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.promo-table-v2 .expire-link {
    display: flex;

    & > svg {
        color: #19cbb1;
        cursor: pointer;
        height: 21px;
        width: 21px;
    }
}

.promo-table-v2 .trash-icon-red > svg {
    color: #ff0000;
    cursor: pointer;
    height: 24px;
    margin-top: 0.15rem;
    width: 24px;
}

.promo-link-action {
    display: flex;
    justify-content: space-evenly;
}

.promo-link-action {
    .d-flex {
        .expire-link,
        .trash-icon-red {
            svg {
                height: 21px;
                width: 21px;
            }
        }
    }
}

.promo-link-responsive {
    .admin-table-row {
        td {
            padding: 14px 20px;
            font-size: 13px;
        }
    }
}

.promo-data-label {
    width: 0px;
}

.table-v2-responsive-row {
    tr {
        td:nth-child(2) {
            background-color: #f6f6f6;
        }
    }
}

.table-v2-responsive-row.promo-link-responsive {
    border-bottom: 8px solid #fff;
}

.align-items-baseline {
    align-items: baseline;
}

.expanded-row .expanded-row-block .info-items h5.sub-value.client-note-width {
    max-width: none;
}

.mt-2 {
    margin-top: 2rem;
}

.mb-3 {
    margin-bottom: 2rem !important;
}

.p-04 {
    padding: 4px;
}

.copy-url-icon {
    margin-left: 0.3rem;
    opacity: 0.5;

    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}

.promo-content-scroller {
    overflow-y: scroll;
    max-height: 80vh;
}

.promolink-tabs.w-100 {
    .tab-text-value {
        padding: 1rem;
    }
}

.promo-link-tab-wrapper {
    div {
        .tab-text-value-disabled {
            pointer-events: all;
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
}

.modalFormMainWrapper .modalMainWrapper.min-width-800 {
    min-width: 900px;
}

.promo-content-scroller::-webkit-scrollbar {
    width: 8px;
}

.promo-content-scroller::-webkit-scrollbar-thumb {
    background-color: #a7a7ba;
    border-radius: 10px;
    border: 1px solid #fff;
}

.promo-content-scroller::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
}
