
          $font-path: "/home/runner/work/spotdif/spotdif/src/assets/fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.page-heading-1 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.2;
    color: rgb(0, 0, 48);
}

.user-lead-stats--conatiner {
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    height: 100%;
    width: 100%;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    display: grid;
    margin-bottom: 30px;

    @media screen and (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 576px) {
        grid-template-columns: 1fr;
    }

    .user-lead-stats--item {
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        align-items: center;
        background-color: #fff;
        border-radius: 0.4rem;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 3.5rem 1rem;
        text-align: center;
        @media screen and (max-width: 576px) {
            padding: 1.5rem 0.7rem;
        }

        h2 {
            font-size: 3.5rem;
            color: $COLOR_PRIMARY;
            font-weight: 700;
            line-height: 1.2;
            cursor: pointer;
            @media screen and (max-width: 1366px) {
                font-weight: 500;
            }

            @media screen and (max-width: 991px) {
                font-size: 3rem;
            }
        }

        h3 {
            color: $DARK_BLUE;
            font-size: 1.8rem;
            font-weight: 500;
            cursor: pointer;

            @media screen and (max-width: 1366px) {
                font-size: 1.5rem;
                line-height: 1;
            }

            @media screen and (max-width: 991px) {
                font-size: 1.3rem;
            }
        }

        p {
            font-size: 1rem;
            color: $NEW_TEXT_BLACK;
            margin-bottom: 0;
            font-weight: 400;
            cursor: pointer;
        }

        &:hover {
            background-color: $COLOR_PRIMARY;
            color: $COLOR_WHITE;

            h2,
            h3,
            p {
                color: $COLOR_WHITE;
            }
        }
    }
}
