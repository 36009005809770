
          $font-path: "/home/runner/work/spotdif/spotdif/src/assets/fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
@import 'styles/global.scss';

.row-expand {
    // display: flex;
    // flex-wrap: wrap;

    .row-expand-item {
        display: flex;
        gap: 8px;
    }
}

.position-relative {
    position: relative;
}

.column-heading {
    color: #5a5a5a !important;
}

.config-heading {
    font-weight: 500 !important;
}

.listing-table {
    overflow-y: auto;
    border-radius: 0.75rem;
    box-shadow: none;
    height: 70vh;
    margin-top: 20px;
    margin-left: 0px;
    position: relative;
    width: 100%;
    // overflow: hidden;
    overflow-x: hidden;

    @media screen and (max-width: 991px) {
        margin-top: 20px;
    }
}

.listing-table-scroll {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: -15px;
    overflow: auto;
}

table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 9px;
    overflow-y: scroll;
}

.table_key-wrap {
    display: flex;
    padding-bottom: 10px;
    justify-content: space-between;

    @media screen and (max-width: 1480px) {
        flex-wrap: wrap;
    }
}

.table_key-left {
    background-color: $COLOR_WHITE;
    border-radius: 3rem;
    padding-right: 0.4rem;
    margin-bottom: 10px;

    @media screen and (min-width: 1480px) {
        height: 38px;
    }

    @media screen and (max-width: 991px) {
        width: 100%;
    }
}

.table_key-left-grid {
    grid-column-gap: 0.4rem;
    grid-row-gap: 0.4rem;
    grid-template-rows: auto;
    grid-template-columns: 1.25fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: flex;
    overflow: hidden;
    border-radius: 20px;
}
.client-table-v2 .table_key-left-grid {
    @media screen and (max-width: 768px) {
        gap: 0;
        padding: 10px 0;
    }
}

@media screen and (max-width: 768px) {
    .table_key-left {
        border-radius: 1rem;
    }

    .table_key-left-grid {
        flex-direction: column;
    }
}

.table_key-right {
    border-radius: 3rem;

    @media screen and (max-width: 991px) {
        width: 100%;
    }
}

.table_key-right-grid {
    height: 100%;
    grid-column-gap: 0.4rem;
    grid-row-gap: 0.4rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: flex-end;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 991px) {
        justify-content: flex-start;
        margin-top: 15px;
    }
}

.table_key-right-col {
    background-color: $COLOR_WHITE;
    border-radius: 2rem;
    align-items: center;
    padding-right: 1.25rem;
    font-size: 0.8rem;
    display: flex;
    overflow: hidden;
    height: 37px;
}

.add-shadow {
    box-shadow: 0 2px 5px $COLOR_BLACK-SHADE_ALPHA;
}

@media screen and (max-width: 400px) {
    .responsiveStatus {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

.search_page {
    display: flex;
}

.newest_leads {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 500px) {
    .table_key-right-col {
        font-size: 0.7rem;
        height: 26px;
    }

    .table_export_leads {
        display: none;
    }

    .industriesLeadButton {
        font-size: 10px;
    }
}

.table_key-right-colour {
    width: 1rem;
    height: 100%;
    margin-right: 0.5rem;
}

.table_key-col {
    padding-left: 0.6rem;
    display: flex;
    align-items: center;
    @media screen and (max-width: 768px) {
        width: 50%;
    }
}

.background-color-orangelight {
    background-color: #ffece3;
}

.background-color-folly {
    background-color: #ff0058;
}

.background-color-carribeangreen {
    background-color: #19cbb1;
}

.background-color-purpleflower {
    background-color: #a94cd3;
}

.background-color-cornflowerblue {
    background-color: #5fa2fb;
}

.background-color-follylight {
    background-color: #ffeef4;
}

.background-color-carribeangreenlight {
    background-color: #e4fffb;
}

.background-color-purpleflowerlight {
    background-color: #f9ecff;
}

.background-color-cornflowerbluelight {
    background-color: #e5f0ff;
}

.background-color-statusblue {
    background-color: #ff4e00;
}

.background-color-statuspaused {
    background-color: #ffa800;
}

.background-color-statusgreen {
    background-color: #1de993;
}

.background-color-statusred {
    background-color: #ee404c;
}

.background-color-statusyellow {
    background-color: #a94cd3;
}

.form-input-small {
    height: auto;
    color: #000;
    background-color: #fff;
    border: 1px solid #cfcfe2;
    border-radius: 0.2rem;
    margin-bottom: 0;
    padding: 0.5rem 0.2rem;
    font-size: 0.8rem;
    font-weight: 300;
    line-height: 1;
}

.form-input-small:focus {
    border-color: #000;
}

.form-input-small::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.4);
    font-weight: 500;
}

.form-input-small::placeholder {
    color: rgba(0, 0, 0, 0.4);
    font-weight: 500;
}

.form-input-small.is-text-area {
    height: auto;
    min-height: 11.25rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    overflow: auto;
}

.form-input-small.is-select-input {
    background-image: none;
}

.form-select-small {
    height: auto;
    color: #79798f;
    background-color: #fff;
    padding: 0px 0.5rem 0 0.5rem;
    border: 1px #e4e6e9;
    border-radius: 30px;
    margin-bottom: 10px;
    font-size: 0.8rem;
    font-weight: 300;
    line-height: 1;
    width: 100%;
    text-overflow: ellipsis;
    &:focus,
    &:hover {
        outline: 0;
    }
}

.form-select-small:focus {
    border-color: #000;
}

.form-select-small::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.4);
    font-weight: 500;
}

.form-select-small::placeholder {
    color: rgba(0, 0, 0, 0.4);
    font-weight: 500;
}

.form-select-small.is-text-area {
    height: auto;
    min-height: 11.25rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    overflow: auto;
}

.form-select-small.is-select-input {
    background-image: none;
}

.table_export_leads {
    .export-csv {
        background-color: $COLOR_BG;
        border: 1px solid $COLOR_BG;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        border-top-left-radius: 100px;
        border-top-right-radius: 100px;
        color: #fff;
        cursor: pointer;
        font-weight: 500;
        text-align: center;
        font-size: 0.8rem;
        padding: 0.6rem 1.25rem;
        box-shadow: 0 2px 5px $COLOR_BLACK-SHADE_ALPHA;

        @media screen and (max-width: 991px) {
            padding-top: 0.6rem;
            padding-bottom: 0.6rem;
        }

        &:hover {
            background-color: transparent;
            color: $COLOR_BG;
        }
    }
}
.table_export_leads_disabled {
    visibility: hidden;
}

table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 10px;
    overflow-y: scroll;

    thead {
        opacity: 1;
        background: $COLOR_WHITE-SHADE_1;
        color: rgb(52, 71, 103);
        box-shadow: none;
        position: sticky;
        top: 0px;
        z-index: 10;
    }

    tr {
        th {
            padding: 18px 36px 24px;
            padding-right: 24px;
            font-weight: 400;
            text-transform: capitalize;
            font-size: 14px;
            min-width: 90px;
            text-align: left;
            background: transparent;
            color: rgb(52, 71, 103);
            box-shadow: none;
            white-space: nowrap;

            @media screen and (max-width: 768px) {
                padding: 0 26px 20px;
                font-size: 14px;
            }

            @media screen and (max-width: 991px) {
                padding-bottom: 15px;
                font-size: 14px;
            }

            button {
                border: 0;
                padding: 0;
                background-color: transparent;
                display: flex;
                cursor: pointer;
            }
        }

        td {
            text-align: left;
            opacity: 1;
            background: rgb(255, 255, 255);
            color: rgb(52, 71, 103);
            box-shadow: none;
            font-size: 1rem;
            border-bottom: 0;
            position: relative;
            border-spacing: 0px 15px;
            padding: 18px 36px;
            white-space: nowrap;

            &.details-row-expand {
                padding-left: 20px;
                padding-right: 20px;
            }

            & > img {
                width: 48px;
                height: 48px;
                object-fit: cover;
                vertical-align: middle;
                border-radius: 50%;
            }

            @media screen and (max-width: 768px) {
                padding: 14px 26px;
                font-size: 14px;
            }

            .MuiBadge-badge {
                background: $COLOR_PRIMARY !important;
                // margin-left: 6px;
                font-size: 11px !important;
            }

            button {
                border: 0;
                padding: 0;
                background-color: transparent;
                display: flex;
                cursor: pointer;
                justify-content: center;
            }

            &:first-child {
                border-radius: 10px 0 0 10px;
            }

            &:first-child:before {
                border-radius: 10px 0 0 10px;
                content: '';
                display: block;
                height: 99%;
                left: 0;
                position: absolute;
                top: 0;
                width: 10px;
                // background-color: rgb(29, 233, 147);
                border-width: 30px;
            }
        }
    }

    td.details-row-expand {
        &:before {
            display: none !important;
        }

        border-top: 0.0625rem solid rgb(240, 242, 245);
        top: -10px;

        & form {
            button {
                font-size: 0.75rem;
                margin: 30px auto 0;
            }

            .expand-form-wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            & > div > div {
                display: flex;
                justify-content: space-between;
                width: 42%;
                min-height: 35px;

                @media screen and (max-width: 950px) {
                    width: 100%;
                }

                label {
                    margin: 0px;
                    font-size: 0.875rem;
                    font-family: $FONT_PRIMARY;
                    opacity: 1;
                    vertical-align: middle;
                    text-decoration: none;
                    color: gray;
                    font-weight: 400;
                    text-transform: capitalize;
                    display: flex;
                    line-height: 1;
                    align-items: center;
                }

                input {
                    border: 0;
                    border-bottom: 1px solid #d2d6da;
                    text-align: end;
                    color: rgb(52, 71, 103);
                    font-size: 15px;
                    min-width: 200px;

                    &:hover,
                    &:focus {
                        outline: 0;
                        border-color: red;
                    }
                }
            }
        }
    }
}

.lead-labels {
    width: 45%;
    text-align: right;
    padding-right: 32px;

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    ul li {
        display: flex;
        margin-bottom: 12px;

        span:last-child {
            margin-left: auto;
        }

        span {
            margin: 0px;
            font-size: 0.875rem;
            font-family: $FONT_PRIMARY;
            opacity: 1;
            text-transform: none;
            vertical-align: unset;
            text-decoration: none;
            color: rgb(52, 71, 103);
        }
    }
}

.note-field {
    margin-top: 15px;

    h2 {
        font-size: 16px;
        color: gray;
        font-weight: 400;
    }

    input {
        display: block;
        border: 0.5px solid rgb(0, 0, 50);
        border-radius: 6px;
        height: 80px;
        resize: none;
        margin-top: 9px;
        cursor: pointer;
        width: 100%;
    }

    button {
        background: rgb(121, 121, 143);
        border-color: rgb(121, 121, 143);
        cursor: pointer;
        width: 100%;
        justify-content: center;
        margin-top: 10px;
    }
}

.lead-form {
    width: 55%;

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    .lead-form-card {
        box-shadow: 0 7px 29px 0 hsla(240, 5%, 41%, 0.2);
        background-color: #fff;
        width: 100%;
        margin-top: 5px;
        padding: 20px 20px 20px 30px;
        border-radius: 10px;
        margin-bottom: 15px;
    }

    .lead-form-field {
        display: flex;
        margin-top: 16px;

        label {
            font-size: 12px;
            font-weight: 400;
        }

        .autocomplete-field {
            margin-left: auto;

            .MuiFormControl-root {
                border-radius: 50px;
            }
        }

        span:last-child {
            margin-left: auto;
            font-size: 12px;
            font-weight: 400;
        }

        .badge {
            margin-left: auto;
            // background-color: gray;
            padding: 5px 12px;
            // color: #000;
            border-radius: 30px;
            min-width: 93px;
            text-align: center;
            font-size: 12px;
            font-weight: 400;

            &.blue {
                background-color: rgb(33, 175, 255);
            }

            &.green {
                background-color: rgb(113, 235, 156);
            }

            &.red {
                background-color: rgb(255, 82, 82);
            }
        }
    }

    .form-button-wrapper {
        input {
            width: 100%;
        }
    }

    &-heading {
        display: flex;

        h6 {
            color: $COLOR_PRIMARY;
            font-size: 14px;
            font-weight: 400;
        }

        label {
            background-color: $COLOR_PRIMARY;
            font-size: 12px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-bottom: 00;
            color: #fff;
            text-align: center;
            line-height: 24px;
            margin-left: auto;
        }
    }
}

.lead-buttons {
    width: 100%;
    text-align: center;
    padding-top: 15px;
}

.MuiBadge-badge {
    font-weight: 400 !important;
}

.generate-link {
    button {
        align-items: center;
        background-color: $COLOR_PRIMARY-SHADE_1;
        border-radius: 30px;
        color: #fff;
        font-weight: 300;
        padding: 0.5rem 1.5rem 0.5rem 1.3rem;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        height: 39px;
    }
}

table td:first-child.Valid::before,
.Valid.badge {
    background-color: #1de993;
}

table td:first-child.Reported::before,
.Reported.badge {
    background-color: #a94cd3;
}

table td:first-child.Report-Rejected::before,
.Report-Rejected.badge {
    background-color: #ee404c;
}

table td:first-child.Report-Accepted::before,
.Report-Accepted.badge {
    background-color: #ff4e00;
}

table td:first-child.Reprocess::before,
.Reprocess.badge {
    background-color: #ffa800;
}

// table td:first-child.is-active::before {
//     background-color: #21afff;
// }
table td:first-child.paused::before,
.paused.badge {
    background-color: #ffa800;
}

table td:first-child.Archived::before,
.Archived.badge {
    background-color: gray;
}

// table td:first-child.paused::before, .paused {
//     background-color: #FFA800;
// }

// CHECK ACTIVE CLASSES
.customCheckBoxChecked,
.customCheckBoxUnchecked {
    border-radius: 30px;
    cursor: pointer;
    display: flex;
    flex: 1 1;
    justify-content: center;
    padding: 9px;

    p {
        font-size: 0.8rem;
    }
}

.customCheckBoxUnchecked {
    background-color: #ffeded;
    color: #333;
    font-weight: bold;
}

.customCheckBoxChecked {
    background-color: #ff5252;
    color: #fff;
}

.spotdif-input-text-right {
    input {
        text-align: right;
    }

    .MuiInputBase-formControl:after:focus {
        border-color: #ff4d02;
    }
}

.client-button {
    button {
        span {
            font-size: 1.6rem !important;
        }
    }
}

.client-deletButton {
    svg {
        font-size: 1.6rem !important;
    }
}

.client-deletButton {
    // margin-top: -0.1rem;
}
.autocomplete-field-client {
    display: flex;
    justify-content: flex-end;
}

.heading-box {
    position: relative;
    width: fit-content;
}

.sort-by {
    &::after,
    &::before {
        border: 7px solid transparent;
        content: '';
        display: block;
        height: 0;
        // right: 5px;
        left: 130%;
        top: 50%;
        position: absolute;
        width: 0;
        // border-bottom-color: #666;
        // border-top-color: #666;
    }

    &.sort-by:before {
        border-bottom-color: #ccc5c5;
        margin-top: -16px;
    }

    &.sort-by:after {
        border-top-color: #ccc5c5;
        margin-top: 1px;
    }
    &.asc:before {
        border-bottom-color: #666;
        margin-top: -18px;
    }
    &.desc:after {
        border-top-color: #666;
        margin-top: 1px;
    }
}
