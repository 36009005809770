
          $font-path: "/home/runner/work/spotdif/spotdif/src/assets/fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.postalCodeModalWrapper {
    display: flex;
    flex-direction: column;
}

.postalModalWrapper {
    display: flex;
    gap: 24px;
    flex-direction: column;
}
.modalMainWrapper.postalModalWrapper {
    @media (max-width: 786px) {
        padding-left: 10px;
        padding-right: 10px;
    }
}
.modalHeadingPostalCode {
    font-size: 22px;
    letter-spacing: 0px;
    color: #ff4e00;
    opacity: 1;
    font-weight: 500;
    margin: 1rem 0rem;
    display: flex;
    align-items: center;
}

@media (max-width: 900px) {
    .modalHeadingPostalCode {
        font-size: 16px !important;
    }

    .postalModalUserName {
        font-size: 14px !important;
    }

    .postalModalContentDetails {
        font-size: 14px !important;
    }
}
@media (max-width: 576px) {
    .modalHeadingPostalCode {
        font-size: 14px !important;
    }

    .postalModalUserName {
        font-size: 12px !important;
    }

    .postalModalContentDetails {
        font-size: 12px !important;
    }
}

.postalModalContentWrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 3rem;
    // max-width: 5rem;
    border-radius: 6px;
    justify-content: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    align-items: center;
    border: 0.5px solid rgb(233, 233, 233);

    @media (max-width: 1024px) {
        height: 2.5rem;
        max-width: 4rem;
    }
    @media (max-width: 576px) {
        height: 2.5rem;
        max-width: 2.5rem;
    }
}

.largePostcodeTile{
    width: max-content;
    padding:5px;
    margin: 0px 0.5rem;
}

.postalModalContentDetails {
    text-align: center;
    letter-spacing: 0px;
    color: $COLOR_BG;
    opacity: 1;
    font-size: 16px;
    font-weight: 400;
}

.postalModalUserName {
    font-weight: 600;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
}

.postalModalGrid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    row-gap: 4px;
    column-gap: 4px;
    @media screen and (max-width: 576px) {
        gap: 2px;
        grid-template-columns: repeat(8, 1fr);
    }
}

.copy-to-clipboard {
    align-items: center;
    justify-content: space-around;
    margin-left: 1rem;
    cursor: pointer;
}

// .expanded-client-row svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.copy-content-icon.css-eimhud-MuiSvgIcon-root {
//     position: absolute;
//     top: 0;
//     right: 10px;
//     bottom: 0;
//     margin: auto;
// }
.text-field-icon {
    svg {
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 0;
        margin: auto;
    }
}
.expanded-client-row .expand-field-value {
    position: relative;
}
.expanded-client-row {
    .spotdif-field {
        div {
            border-radius: 30px !important ;
        }
    }
}
// .expanded-client-row {
//     .note-textarea {
//         input {
//             height: 100px;
//         }

//         ::-webkit-input-placeholder {
//             /* Chrome/Opera/Safari */
//             position: relative !important;
//             transform: translateY(-220%) !important;
//         }
//         input::-moz-placeholder {
//             /* Firefox 19+ */
//             vertical-align: top;
//         }
//         input::-ms-input-placeholder {
//             /* IE 10+ */
//             vertical-align: top;
//         }
//     }
// }
