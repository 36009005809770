
          $font-path: "/home/runner/work/spotdif/spotdif/src/assets/fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #f4f4fa;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    height: 100%;
    object-fit: cover;
}
.how-it-works-title {
    font-size: 3rem;
    color: #142f73;
    text-align: center;
    @media screen and (max-width: 768px) {
        font-size: 2.5rem;
    }
    // margin: 5rem 0px;
}
.how_its_work_sec {
    padding: 80px 0 30px 0;
    @media screen and (max-width: 580px) {
        .swiper-slide {
            width: 100% !important;
        }
    }
}
.how_its_work_sec .swiper.swiper-initialized.swiper-horizontal.mySwiper.swiper-backface-hidden {
    margin-top: 50px;
    @media screen and (max-width: 768px) {
        display: none;
    }
}
.how_its_work_sec .swiper.swiper-initialized.swiper-horizontal.mySwiper-responsive.swiper-backface-hidden {
    display: none;
    @media screen and (max-width: 768px) {
        display: block;
    }
}
.how_its_work_sec .swiper-button-prev,
.how_its_work_sec .swiper-button-next {
    bottom: 0;
    width: calc(50% - 100px);
    height: 20px;
    top: inherit;
}
.swiper-scrollbar-drag {
    background: #19cbb1;
}
.how_its_work_sec .swiper-button-prev {
    justify-content: end;
}
.how_its_work_sec .swiper-button-next {
    justify-content: start;
}
.how_its_work_sec .swiper-android .swiper-slide,
.how_its_work_sec .swiper-ios .swiper-slide,
.how_its_work_sec .swiper-wrapper {
    padding: 15px 0 32px;
}
.how_its_work_sec .swiper-button-prev:after,
.how_its_work_sec .swiper-button-next:after {
    color: #000;
    font-size: 16px;
    font-weight: 700;
}
.how_its_work_sec .swiper-horizontal > .swiper-scrollbar,
.how_its_work_sec .swiper-scrollbar.swiper-scrollbar-horizontal {
    width: 100px;
    left: 0 !important;
    right: 0 !important;
    margin: auto;
    bottom: 7px;
    top: inherit;
    height: 6px;
}
