
          $font-path: "/home/runner/work/spotdif/spotdif/src/assets/fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.advertisement-card {
    background-color: #fff;
    border-radius: 20px;
    margin: 0.5rem;

    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    gap: 8px 0px;
    grid-template-areas:
        "."
        ".";

    .advertisement-promotion {
        position: relative;
        min-width: 360px;
        max-width: 420px;
        overflow: hidden;
        margin: 0 auto;
        top: -48px;
        padding: 8px;
        font-size: 1rem;
        background: $COLOR_PRIMARY;
        color: white;
        border-radius: 32px;
        text-align: center;
    }
}

.advertisement-icon {
    img {
        height: 100px;
        width: 300px;
        object-fit: cover;
    }
}

.grid-responsive {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    grid-template-rows: auto;
}

.description {
    font-size: 15px;
}

@media screen and (max-width: 800px) {
    .grid-responsive {
        grid-template-columns: 1fr; // Display two items per row on larger screens
    }

    .advertisement-card {
        width: fit-content;
    }
}

.description_box {
    word-break: break-word !important;
    min-height: 221px;
}

.advertisement-icon img {
    width: unset !important;
    height: 53px !important;
}

.card_image_box {
    width: unset;
    margin-bottom: 8px;
}

.head_card_box {
    margin-bottom: 7px !important;
    font-size: 20px !important;
    font-weight: 700 !important;
}

body .button.button-outline-primary {
    font-weight: 600 !important;
}


.list-card-parent {
    gap: 25px !important;
    margin-top: 25px !important;
}

.no_data_box {
    background-color: #fff;
    border-radius: 20px;
    padding: 1.5rem;
    text-align: center;
    width: 100%;
    max-width: 100%;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no_data_box h3 {
    font-size: 30px;
    font-weight: 600;
    line-height: 29px;
    color: #344767;
}

@media (min-width: 768px) and (max-width: 991px) {
    .advertisement-card {
        width: 48% !important;
        max-width: 48% !important;
        padding: 0.8rem;
        margin: 0 0 1rem !important;

        .advertisement-promotion {
            top: -32px;
            max-width: 380px;
            min-width: 320px;
        }
    }
    .list-card-parent {
        display: flex !important;
        flex-wrap: wrap;
    }
    .description {
        font-size: 14px;
    }

    .no_data_box h3 {
        font-size: 19px;
        line-height: 29px;
    }
    .no_data_box {
        min-height: 220px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .advertisement-card {
        width: 100% !important;
        margin: 0 0 37px !important;
        padding: 0.5rem;

        .advertisement-promotion {
            top: -24px;
        }
    }

    .no_data_box h3 {
        font-size: 19px;
        line-height: 29px;
    }
    .no_data_box {
        min-height: 220px;
    }
}
