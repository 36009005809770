
          $font-path: "/home/runner/work/spotdif/spotdif/src/assets/fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.modalMainWrapper.leadingReportWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px;
}

@media (max-width: 568px) {
    .modalMainWrapper.leadingReportWrapper {
        padding-bottom: 32px !important;
    }
}

.leadReportModalHeadingWrapper {
    display: flex;
    flex-direction: column;
    gap: 21px;
}

.leadReportModalHeading {
    text-align: left;
    letter-spacing: 0px;
    color: $COLOR_BG;
    opacity: 1;
    font-size: 30px;
    font-weight: 600;
}
@media (max-width: 900px) {
    .leadReportModalHeading {
        font-size: 18px !important;
    }
    .MuiTypography-root.leadReportModalHeadingInfo {
        font-size: 14px !important;
    }
    .reportLegendContent {
        padding: 13px 15px !important;
    }
    .leadingReportWrapperMobile {
        padding: 26px !important;
    }
    .leadReportCloseBtnWrapperMobile {
        margin-top: 0px !important;
    }
}

.leadReportModalLegends {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.leadReportModalHeadingInfo {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: $GRAY-1;
    opacity: 1;
    margin-bottom: 20px;
}

.leadReportStyles {
    gap: 63px;
}

.leadReportCloseBtnWrapper {
    display: flex;
    justify-content: center;
}

.leadReportingCloseBtn {
    border-radius: 50%;
    width: 57px;
    height: 57px;
    background-color: #f4f4fa;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leadReportModalGetInTouchLink {
    color: $GRAY-1;
    text-align: center;
    font-size: 16px;
    letter-spacing: 0px;
    font-weight: 500;
    margin-top: 20px;
}

@media (max-width: 568px) {
    .leadReportModalGetInTouchLink {
        display: none;
    }
}

.leadReportModalGetInTouchLink span {
    color: #ff4e00;
}
