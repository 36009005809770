
          $font-path: "/home/runner/work/spotdif/spotdif/src/assets/fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
@import './../../styles/variables';

.industries-leads {
    .table-card-block {
        width: 50%;
    }

    .table-v2-block {
        table {
            .active-row td {
                background-color: $ACTIVE_ROW;
            }

            th,
            td {
                text-align: left !important;
                padding-left: 50px !important;

                .status-img {
                    border-radius: 0;
                    width: 20px;
                    height: 20px;
                }
            }
            
            th:first-child,td:first-child{
                padding-left: 50px !important;
                // padding-right: 0/px !important;
    
            }


            .loading-center {
                text-align: center !important;
            }

            .expanded-row {
                td {
                    padding: 0px !important;
                }

                // .expanded-row-block {
                //     padding: 0;

                // }

                .expanded-row-block {
                    flex-direction: column;

                    .upper-block {
                        display: flex;
                        gap: 15px;
                        width: 100%;

                        .left-block,
                        .right-block {
                            width: 50%;
                            padding: 20px;
                            border: solid 1px $COLOR_LABEL;
                            border-radius: 10px;

                            h2.block-heading {
                                font-size: 20px;
                                font-weight: 500;
                                color: $COLOR_PRIMARY;
                                padding-bottom: 10px;
                            }
                        }

                        .left-block {
                            .action-toggler-tile {
                                padding: 20px;
                                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                                border-radius: 10px;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                h2 {
                                    font-size: 20px;
                                    font-weight: 500;
                                    color: $HEADING_COLOR_1;
                                }
                            }

                            .industry-details-block {
                                margin-top: 22px;

                                // h2
                                .error-v2 {
                                    text-align: right;
                                }

                                .name-row,
                                .total-sum {
                                    display: flex;
                                    justify-content: space-between;
                                    margin-top: 15px;
                                    margin-bottom: 10px;

                                    h3 {
                                        font-size: 16px;
                                        font-weight: 400;
                                        color: $HEADING_COLOR_1;
                                    }

                                    input {
                                        border: none;
                                        text-align: right;
                                        font-size: 16px;
                                        font-weight: 400;
                                        color: $HEADING_COLOR_1;

                                        &:focus {
                                            border: none;
                                            outline: none;
                                        }
                                    }
                                }

                                .total-sum h3,
                                .total-sum input {
                                    display: flex;
                                    flex-direction: column;

                                    .cpl-input {
                                        display: flex;
                                        justify-content: flex-end;
                                        align-items: baseline;
                                    }

                                    font-weight: 600 !important;

                                    .error-v2 {
                                        font-weight: 400;
                                    }
                                }

                                .json-data {
                                    padding: 20px;
                                    background: #f1f1f6;
                                    margin-top: 20px;
                                    border-radius: 10px;
                                    position: relative;

                                    .copy-content-icon {
                                        position: absolute;
                                        top: 16px;
                                        right: 45px;
                                        cursor: pointer;
                                    }

                                    h3 {
                                        font-size: 18px;
                                        font-weight: 600;
                                        color: $HEADING_COLOR_1;
                                    }

                                    .json {
                                        padding-top: 20px;
                                        //  max-height: 300px;
                                        //  overflow-y: auto;

                                        &::-webkit-scrollbar {
                                            width: 6px;
                                            height: 6px;
                                        }

                                        &::-webkit-scrollbar-thumb {
                                            background-color: #afb8cc;
                                            // outline: 1px solid slategrey;
                                        }

                                        &::-webkit-scrollbar-track {
                                            box-shadow: inset 0 0 6px #dfe2e9;
                                        }
                                    }
                                }
                            }
                        }

                        .right-block {
                            h2.block-heading {
                                color: $HEADING_COLOR_1;
                            }

                            ul.column-lists {
                                list-style: none;
                                padding: 0;
                                // overflow-y: auto;
                                //  max-height: 480px;
                                padding: 0;
                                margin-bottom: 20px;

                                &::-webkit-scrollbar {
                                    width: 6px;
                                }

                                &::-webkit-scrollbar-thumb {
                                    background-color: #afb8cc;
                                }

                                &::-webkit-scrollbar-track {
                                    box-shadow: inset 0 0 6px #dfe2e9;
                                }

                                li {
                                    display: flex;
                                    gap: 20px;
                                    // margin-bottom: 15px;
                                    padding-bottom: 15px;
                                    padding-left: 0;

                                    .input-block {
                                        width: calc(100% - 180px);

                                        & .spotdif-field > label {
                                            margin: 0;
                                        }

                                        & > div {
                                            width: 50%;
                                        }
                                    }

                                    i {
                                        width: 44px;
                                        height: 44px;
                                        border: 1px solid $BORDER_COLOR;
                                        border-radius: 100%;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        color: $COLOR_PRIMARY;
                                        cursor: pointer;
                                    }

                                    &:first-child {
                                        position: sticky;
                                        top: 0;
                                        left: 0;
                                        z-index: 9;
                                        background: #fff;

                                        div {
                                            width: 100%;
                                            display: flex;
                                            justify-content: space-around;
                                        }

                                        & > div {
                                            border-bottom: 1px solid $BORDER_COLOR;
                                            // padding-bottom: 10px;
                                        }

                                        i {
                                            visibility: hidden;
                                        }
                                    }

                                    h4 {
                                        font-size: 18px;
                                        line-height: 18px;
                                        font-weight: 400;
                                        color: $HEADING_COLOR_1;
                                        width: 90px;
                                        margin-top: 14px;
                                    }

                                    .spotdif-field {
                                        width: auto !important;
                                    }

                                    .input-block {
                                        display: flex;
                                        gap: 20px;

                                        .error-v2 {
                                            padding-left: 18px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .lower-block {
                        margin-top: 20px;
                        margin-bottom: 30px;

                        .action-btn-block {
                            display: flex;
                            justify-content: center;
                            gap: 20px;

                            .button {
                                padding-left: 35px;
                                padding-right: 35px;
                            }

                            button.button-outline-gray {
                                color: $COLOR_BG !important;

                                &:hover {
                                    color: #fff !important;
                                    background-color: $COLOR_BG !important;
                                }
                            }
                        }
                    }
                }
            }

            .expand-icon {
                width: 30px;
                height: 30px;
                cursor: pointer;
            }

            .active-expand-icon {
                color: $COLOR_HEADING_V2;
            }

            .delete-icon {
                svg {
                    color: $COLOR_HEADING_V2;
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    .form-groups {
        .contact-price {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            width: 100%;

            .price-input {
                display: flex;
                gap: 10px;
                width: 48%;
                align-items: end;

                // .spotdif-outlined-field {
                //     width: 70%;
                // }

                .left {
                    width: 70%;
                }
                .right {
                    width: 30%;
                    display: flex;
                    justify-content: center;
                }
            }

            // .spotdif-outlined-field {
            //     width: 48% !important;
            // }
        }
    }
}

.hover-animation {
    &:hover {
        transform: scale(1.1);
    }
}

.badges-wrapper {
    // display: flex;
    width: 100%;
    // justify-content: space-between;
    border: solid 1px #9fa1b3;
    border-radius: 10px;
    padding: 20px;

    .banner-block-wrapper,
    .badge-block-wrapper,
    .post-block-wrapper {
        display: flex;
        width: 100%;
        margin-top: 10px;
        gap: 20px;

        .banner-block,
        .badge-block,
        .post-block,
        .image-preview-box {
            width: 50%;

            .spotdif-field {
                width: 100% !important;
                margin-top: 15px;
            }
        }
    }
}

.ceritified-images {
    p {
        color: red;
    }
}

.h-150 {
    height: 150px;
    object-fit: contain;
}

.max-w-150 {
    max-width: 150px;
}

.max-h-150 {
    max-height: 150px;
}

.industry-tabs.w-100 {
    z-index: 1;
    .tab-text-value {
        padding: 1rem;
    }
}

.matrix-block {
    border: solid 1px #9fa1b3;
    border-radius: 10px;
    padding: 20px;
    margin: 0 30px 20px;

    .matrix-heading {
        font-size: 24px;
        margin-bottom: 16px;
    }

    .matrix-table {
        .matrix-content-row:last-child {
            border: none;
        }
        .matrix-content-row:first-child {
            padding-top: 0;
        }

        .matrix-content-row {
            display: flex;
            gap: 10px;

            padding-top: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid #999;

            & > :first-child {
                font-weight: 600;
            }
            .matrix-content-column {
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 10px;
                min-width: 180px;
                .matrix-criteria {
                    background: #dedede66;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }

                .matrix-credits {
                    background: #dedede66;
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                }

                .add-remove {
                    display: flex;
                    justify-content: space-around;
                }

                .remove-coloumn {
                    position: absolute;
                    top: 0;
                    right: 0;
                    transform: translate(50%, -50%);
                    width: 26px;
                    height: 26px;
                    font-size: 14px;
                    padding: 4px;
                    border-radius: 100%;
                    font-weight: bold;
                    color: #fff;
                    border: none;
                    background: #ff4e02;
                    cursor: pointer ;
                }

                .matrix-criteria,
                .matrix-credits {
                    .spotdif-field {
                        input {
                            padding: 0px !important;
                        }

                        .MuiFormControl-root {
                            > div {
                                padding: 8px 12px 8px 8px !important;
                            }
                        }

                        button.MuiButtonBase-root {
                            padding-left: 0px !important;
                        }
                    }

                    padding: 20px;
                }
            }
        }
    }

    .matrix-action-block {
        display: flex;
        justify-content: center;
    }
}
