
          $font-path: "/home/runner/work/spotdif/spotdif/src/assets/fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.buisness-oepning-hours-wrapper {
    .buisness-oepning-hours-row {
        display: flex;
        margin-bottom: 10px;
        align-items: center;

        input {
            &:focus,
            &:visited {
                border-color: $COLOR_PRIMARY;
                outline: 0;
            }
        }

        & > label {
            margin-left: 6px;
            margin-right: 10px;
            width: 140px;
            display: flex;
            align-items: center;

            @media screen and (max-width: 576px) {
                width: 110px;
            }

            & > span {
                padding: 0;

                svg {
                    border-radius: 50%;
                    width: 22px;
                    height: 22px;
                }

                &.Mui-checked {
                    svg {
                        background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"),
                            linear-gradient(195deg, $COLOR_PRIMARY, $COLOR_PRIMARY);
                        border-color: $COLOR_PRIMARY;
                    }
                }
            }

            .check-label {
                margin-left: 10px;
                color: $COLOR_BG;
                font-size: 16px;

                @media screen and (max-width: 576px) {
                    font-size: 14px;
                }
            }
        }

        .input-cols {
            display: flex;
            width: calc(100% - 162px);
            margin-left: auto;
            align-items: center;

            .spotdif-field {
                width: auto;
                margin-bottom: 0;
                padding: 0;
            }

            @media screen and (max-width: 576px) {
                width: calc(100% - 110px);
            }

            .hyphen {
                padding: 0 10px;
            }
        }
    }
}
