.disabled-wrapper {
    cursor: not-allowed !important;

    .disabled {
        // cur: not-allowed;
        pointer-events: none;
        opacity: 0.4;
    }
}

.seprator {
    height: 1px;
    background-color: $BORDER_COLOR;
}

.error-v2 {
    color: $COLOR_PRIMARY;
    font-size: 14px;
}

.d-none {
    display: none !important;
}

.w-33{
    width: 33% !important;
}

.w-66{
    width: 66% !important;
}