
          $font-path: "/home/runner/work/spotdif/spotdif/src/assets/fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.client-leads-v2 {
    .client-leads-table {
        th,
        td {
            padding-left: 12px !important;
            padding-right: 12px !important;
        }

        th:first-child,
        td:first-child {
            padding-left: 0px !important;
            // padding-right: 0/px !important;
        }
    }
    .row-action-box {
        display: flex;
        justify-content: space-around;
        // gap: 18px
        gap: 10px;
    }

    td:last-child {
        // display: inherit;
    }

    .contract.lead-sale {
        cursor: pointer;

        &.disabled {
            cursor: not-allowed;
            filter: grayscale(1);
        }

        .lead-sale--container {
            align-items: flex-start;
            min-height: 92px;

            .lead-sale--status {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 1px solid $CARD-WRAPPER-DARK;
                border-radius: 32px;
                padding: 0 8px 0 16px;
                margin: 4px 0;

                p {
                    font-size: 0.9rem;
                    line-height: 1.2rem;
                    height: 1.7rem;
                }
            }
        }
    }

    .expanded-row-block {
        max-height: 482px;
        max-height: 471px;
        max-height: fit-content;

        &.is-hydrated {
            // nothing yet
        }

        &.is-not-hydrated {
            cursor: not-allowed;
            filter: blur(3px) grayscale(0.3);
        }

        .block-left {
            max-height: 480px;
            overflow: auto;
            width: 30% !important;
            padding: 30px 25px !important;

            .info {
                gap: 10px;
            }
        }

        .admin-leads-more-details {
            width: 60% !important;
        }

        .block-right {
            width: 35% !important;

            .info-items {
                display: block !important;
            }

            .report-reason {
                p {
                    color: #79798f;
                    font-size: 12px;
                    font-weight: 400;
                    margin-top: 12px;
                }

                .spotdif-field {
                    width: 100% !important;
                }
            }
        }

        .admin-leads-report-lead {
            width: 40% !important;
        }

        .block-middle {
            .expanded-heading {
                margin-bottom: 22px;
            }

            .action-block {
                p {
                    color: #79798f;
                    font-size: 12px;
                    font-weight: 400;
                    margin-top: 12px;
                }

                textarea {
                    border: 1px solid $COLOR_BORDER_V2;
                    border-radius: 8px;
                    padding: 10px;
                }

                input[type='button'] {
                    margin-top: 25px;
                }

                .spotdif-field {
                    width: 100% !important;
                }
            }

            width: 35%;
            padding: 30px 30px;
            border: 1px solid $COLOR_BORDER_V2;
            border-radius: 8px;
            // display: flex;
            // justify-content: space-between;
            // flex-direction: column;
        }
    }

    .leads-tab {
        border: 1px solid #bfbfbf;
        padding: 7px 25px 7px 8px;
        display: flex;
        gap: 22px;
        align-items: center;
        width: fit-content;
        border-radius: 40px;
        background: #fff;
        margin-bottom: 22px;

        button {
            // width: 160px;
            font-size: 18px;
            color: $COLOR_BG;
            font-weight: 500;
            border: none;
            background: none;
            padding: 10px 26px;
            cursor: pointer;
            margin-right: 20px;
        }

        .active-tab {
            // color: #fff;
            background: $SPOTDIF_GREEN;
            font-weight: 600;
            font-size: 16px;
            border: 1px solid #bfbfbf;
            padding: 12px 28px;
            border-radius: 25px;
        }
    }
}

// LOADING STYLES
.table-loading {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin: 10px 0 15px;
}
