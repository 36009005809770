
          $font-path: "/home/runner/work/spotdif/spotdif/src/assets/fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
@import 'styles/global.scss';

* {
    font-family: $FONT_PRIMARY;
}

body {
    color: #000 !important;
    font-family: Euclid circular a, sans-serif !important;
    font-size: 1rem;
    line-height: 1.5;
}
.pointer-none {
    pointer-events: none;
}
h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1.2;
    color: $DARK_BLUE;
}

h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
}

h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.2;
}

h4 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.3;
}

h5 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.4;
}

h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.4;
}

p {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
}

a {
    color: #000;
    border-color: #000;
    text-decoration: underline;
}

ul {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 1.25rem;
}

ol {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 1.5rem;
}

li {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    padding-left: 0.5rem;
}

img {
    max-width: 100%;
    display: inline-block;
}

label {
    margin-bottom: 0.25rem;
    font-weight: 500;
}

//predefined class
.mt-1 {
    margin-top: 5px;
}

.ml-1 {
    margin-left: 5px;
}

.mb-1 {
    margin-bottom: 5px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-2 {
    margin-bottom: 20px;
}

.w-100 {
    width: 100% !important;
}

.w-50 {
    width: 50% !important;

    @media screen and (max-width: 768px) {
        width: 100% !important;
    }
}

.w-440 {
    width: 440px !important;

    @media screen and (max-width: 768px) {
        width: 100% !important;
    }
}

.text-center {
    text-align: center !important;
}
.bg-white {
    background-color: white;
}
.opacity-50 {
    opacity: 0.5;
}

.p-1 {
    padding: 10px;
}
.pl-1 {
    padding: 10px !important;
}
.border-0 {
    border-width: 0;
}
.pointer {
    cursor: pointer;
}
.spotdif-logo {
    position: relative;
    transition: all 0.2s;
    margin-bottom: 2px;
    width: 230px;
    margin-top: 3rem;

    @media (max-width: 768px) {
        margin-top: 24px;
        width: 200px;
    }

    @media (max-width: 576px) {
        margin-top: 24px;
        width: 160px;
    }

    @media (max-width: 320px) {
        margin-top: 20px;
        width: 145px;
    }
}

.spotdif-base-container {
    background-color: $COLOR_BG;
    min-height: 100vh;
}
.text-warning {
    color: #ff5252;
}
.p-relative {
    position: relative;
}

.MuiTextField-root.spotdif-field {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-text-fill-color: $COLOR_WHITE;
        -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
        box-shadow: 0 0 0 1000px transparent inset !important;
        transition: background-color 5000s ease-in-out 0s;
        color: $COLOR_WHITE;
        caret-color: $COLOR_WHITE;
    }
}

.spotdif-login-footer {
    height: 60px;
    position: relative;
    width: 100%;
    opacity: 1;
    margin-bottom: 0 !important;
}

@media (max-width: 576px) {
    .login-footer {
        margin-top: 0px !important;
        margin-bottom: 15px !important;
    }
}

// -------------- below is copied from signup moule.css

.spotdif-textbox-flex {
    display: flex;
    justify-content: center;
    gap: 22px;

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 10px !important;
        margin-top: 10px !important;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        gap: 1rem;
        margin-top: 14px !important;
    }
}

.signup-field {
    border-radius: 37px;
}

.spotdif-field {
    border-radius: 30px;

    // background: transparent !important;
    &.dropdown {
        height: 44px;
    }

    label {
        margin-left: 0.65rem;
        font-size: 0.8em;
        color: $COLOR_LABEL;
        text-align: left;
        font-weight: 400;
        letter-spacing: 0;
        width: 100%;
        display: block;
    }

    fieldset {
        border-radius: 20px;
    }

    input {
        width: 100%;
    }

    legend {
        margin-left: 8px;
    }

    .Mui-focused {
        fieldset {
            border-color: $COLOR_PRIMARY !important;
        }
    }

    label.Mui-focused {
        color: $COLOR_PRIMARY !important;
    }

    .MuiFormControl-root {
        width: 100%;
        border-radius: 12px;
        background-position: top 14px right 14px;
    }
}

.row-expand {
    .spotdif-field {
        .MuiFormControl-root {
            border-radius: 0;
        }
    }
}

@media (max-width: 576px) {
    .MuiTypography-root.spotdif-signup--text-welcome {
        margin-top: 0 !important;
        margin-bottom: 18px !important;
    }

    .MuiCardContent-root.signup-card--body {
        // padding: 0px !important;
        padding-top: 3px;
        padding-bottom: 3px !important;
        padding-left: 6px;
    }
}

.spotdif-signup-cards {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 20px;

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        gap: 5px;
    }
}

.MuiButtonBase-root.MuiButton-root.spotdif-signup-Button {
    background-color: $COLOR_BG;
    border-radius: 37px;
    padding: 10px 40px;
    color: $COLOR_WHITE;
    transition: all 500ms ease-in-out;
    font-size: 18px;
    font-weight: 400;
    text-transform: none;

    &:hover {
        background-color: $COLOR_BG;
        transform: scale(1.1);
    }

    &:focus {
        box-shadow: none;
        background-color: $COLOR_PRIMARY;
    }
}

.MuiTypography-root.spotdif-signup--text-welcome {
    font-weight: 500;
    color: $COLOR_WHITE;
    margin: 1rem 0 2rem 0;
}

.MuiCheckbox-root.checkbox-border {
    svg {
        border-radius: 50px;
    }
}

.spotdif-custom-error-message {
    color: $COLOR_ERROR;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
}

// toggle input
.MuiSwitch-root {
    .Mui-checked + .MuiSwitch-track {
        background-color: $COLOR_BG;
        border-color: $COLOR_BG;
    }
}

// badge
.badge {
    height: auto;
    padding: 0.55em 0.9em;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border: none;
    border-radius: 0.375rem;
    background: $COLOR_BG;
    color: #ffffff;
    position: relative;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    transform: none;
}

.d-flex {
    display: flex;
}
.pt-0 {
    padding-top: 0 !important;
}
.align-items-center {
    align-items: center;
}
.d-flex-end {
    display: flex;
    justify-content: flex-start;
}

body .button {
    // background-color: $COLOR_PRIMARY-SHADE_1;
    // border: 1px solid $COLOR_PRIMARY-SHADE_1;
    border: 1px solid $COLOR_BG;
    background-color: $COLOR_BG;
    border-radius: 100px;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
    padding: 12px 20px 13px;
    text-align: center;
    font-size: 16px;
    line-height: 16px;
    text-transform: capitalize !important;
    min-width: 135px;
    max-height: 45px;
    display: block;

    @media screen and (max-width: 1280px) {
        font-size: 14px;
    }

    &:hover {
        color: $COLOR_BG;
        background: #fff;
    }

    &:focus,
    &.visited,
    &.Mui-disabled,
    &.mui-loading,
    &.Mui-disabled:hover,
    &.mui-loading:hover {
        // background: $COLOR_PRIMARY-SHADE_1 !important;
        background: $COLOR_BG !important;
        color: #fff !important;
    }

    &.button-inverted {
        background-color: transparent;

        color: $COLOR_PRIMARY-SHADE_1;
        border: 1px solid $COLOR_PRIMARY-SHADE_1;

        &:hover {
            // background-color: transparent;
            background-color: $COLOR_PRIMARY-SHADE_1;

            color: #fff;
        }
    }

    &.button-gray {
        background-color: $GRAY;
        border: 1px solid $GRAY;

        &:hover {
            background: transparent;
            color: $GRAY;
        }

        &:focus,
        &.visited,
        &.Mui-disabled,
        &.mui-loading,
        &.Mui-disabled:hover,
        &.mui-loading:hover {
            background: $GRAY !important;
            color: #fff !important;
        }
    }

    &.button-green {
        background-color: $COLOR_GREEN-SHADE_1;
        border: 1px solid $COLOR_GREEN-SHADE_1;

        &:hover {
            background-color: transparent;
            color: $COLOR_GREEN-SHADE_1;
        }

        &:focus,
        &.visited,
        &.Mui-disabled,
        &.mui-loading,
        &.Mui-disabled:hover,
        &.mui-loading:hover {
            background: $COLOR_GREEN-SHADE_1 !important;
            color: #fff !important;
        }
    }

    &.button-green-inverted {
        background-color: transparent;

        // background-color: $COLOR_GREEN-SHADE_1;
        color: $COLOR_GREEN-SHADE_1;
        border: 1px solid $COLOR_GREEN-SHADE_1;

        &:hover {
            // background-color: transparent;
            background-color: $COLOR_GREEN-SHADE_1;

            color: #fff;
        }

        &:focus,
        &.visited,
        &.Mui-disabled,
        &.mui-loading,
        &.Mui-disabled:hover,
        &.mui-loading:hover {
            background: $COLOR_GREEN-SHADE_1 !important;
            color: #fff !important;
        }
    }

    &.button-blue {
        background-color: $COLOR_BG;
        border: 1px solid $COLOR_BG;

        &:hover {
            background-color: transparent;
            color: $COLOR_BG;
        }

        &:focus,
        &.visited,
        &.Mui-disabled,
        &.mui-loading,
        &.Mui-disabled:hover,
        &.mui-loading:hover {
            background: $COLOR_BG !important;
            color: #fff !important;
        }
    }

    // BUTTON
    &.button-outline-primary {
        color: $COLOR_BG !important;
        background-color: #fff !important;
        border: 1px solid $COLOR_BG !important;

        &:hover {
            background-color: $COLOR_BG !important;
            color: #fff !important;
        }

        &:focus,
        &.visited,
        &.Mui-disabled,
        &.mui-loading,
        &.Mui-disabled:hover,
        &.mui-loading:hover {
            background: $COLOR_BG !important;
            color: #fff !important;
        }
    }

    &.button-outline-blue {
        color: $COLOR_BLUE-SHADE_1 !important;
        background-color: #fff !important;
        border: 1px solid $COLOR_BLUE-SHADE_1 !important;

        &:hover {
            background-color: $COLOR_BLUE-SHADE_1 !important;
            color: #fff !important;
        }

        &:focus,
        &.visited,
        &.Mui-disabled,
        &.mui-loading,
        &.Mui-disabled:hover,
        &.mui-loading:hover {
            background: $COLOR_BLUE-SHADE_1 !important;
            color: #fff !important;
        }
    }

    &.button-ghost {
        color: $COLOR_BLUE-SHADE_1;
        border: 0;
        background: transparent;
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        position: relative;

        &:hover {
            color: $COLOR_BLUE-SHADE_1;
            border: 0;

            &:after {
                content: '';
                color: $COLOR_BLUE-SHADE_1;
                position: absolute;
                left: 0;
                width: 100%;
                height: 1px;
                bottom: -3px;
                background: #344767;
            }
        }

        &:focus,
        &.visited,
        &.Mui-disabled,
        &.mui-loading,
        &.Mui-disabled:hover,
        &.mui-loading:hover {
            background: transparent !important;
            color: $COLOR_BLUE-SHADE_1 !important;
            border: 0;
        }

        @media screen and (max-width: 576px) {
            width: auto;
            margin: 0 auto;
        }
    }

    &.button-outline-gray {
        color: $GRAY !important;
        background-color: #fff !important;
        border: 1px solid $GRAY !important;

        &:hover {
            color: #fff !important;
            background-color: $GRAY !important;
        }

        &:focus,
        &.visited,
        &.Mui-disabled,
        &.mui-loading,
        &.Mui-disabled:hover,
        &.mui-loading:hover {
            background: $GRAY !important;
            color: #fff !important;
        }
    }

    &.button-outline-gray-light {
        color: $COLOR_BG !important;
        background-color: #fff !important;
        border: 1px solid $GRAY-1 !important;

        &:hover {
            color: #fff !important;
            background-color: $GRAY-1 !important;
        }

        &:focus,
        &.visited,
        &.Mui-disabled,
        &.mui-loading,
        &.Mui-disabled:hover,
        &.mui-loading:hover {
            background: $GRAY-1 !important;
            color: #fff !important;
        }
    }

    &.button-solid-dark {
        background: $COLOR_BG !important;
        color: #fff !important;
        border: 1px solid $COLOR_BG !important;

        &:hover {
            color: $COLOR_BG !important;
            background: transparent !important;
        }

        &:focus,
        &.visited,
        &.Mui-disabled,
        &.mui-loading,
        &.Mui-disabled:hover,
        &.mui-loading:hover {
            background: $COLOR_BG !important;
            color: #fff !important;
        }
    }
}
.button-success-outline {
    outline: lightgreen;
    border: 2px solid lightgreen;
    border-radius: 22px;
    color: lightgreen;
    text-transform: capitalize;
    padding: 9px 29px;
}

//checkbox
.Mui-checked {
    svg {
        background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"),
            linear-gradient(195deg, $COLOR_PRIMARY, $COLOR_PRIMARY) !important;
        border-color: $COLOR_PRIMARY !important;
    }
}

// table
.table-responsive {
    overflow-x: auto;
}

table {
    tr {
        th:first-child:last-child {
            display: flex;
            justify-content: flex-end;
        }
    }

    tr.user-list-wrapper th {
        font-weight: 700;
    }

    tr.user-list-wrapper th,
    tr.user-list-wrapper td {
        padding: 10px;
        border-bottom: 1px solid #eee;
        padding-left: 0;
    }

    .no-data {
        background-color: #ffffff;
        border-radius: 10px;
        margin-bottom: 10px;
        text-align: center;
        padding: 50px 0;
        margin-right: 18px;
        box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
    }
}

.no-data {
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
    text-align: center;
    padding: 50px 0;
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
}
.no-data-centered {
    display: flex;
    height: 87%;
    align-items: center;
    justify-content: center;
}

// dropdown
.DropdownContainer {
    margin-left: 15px;
}

// card
.spotdif-card {
    background: #fff 0 0 no-repeat padding-box;
    border-radius: 10px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.102);
    opacity: 1;
    padding: 55px 35px;
    margin-bottom: 30px;

    @media screen and (max-width: 900px) {
        padding: 30px;
    }

    @media screen and (max-width: 768px) {
        padding: 25px;
    }

    @media screen and (max-width: 480px) {
        padding: 15px;
    }
}

// modal
.modal-wrapper {
    background-color: rgba(0, 0, 50, 0.1);
}

.boxModalStyles {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-width: 738px;

    @media (max-width: 900px) {
        min-width: 96%;
        width: 100% !important;
    }
}

.modalFormMainWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .modalMainWrapper {
        padding: 30px;
        border-radius: 20px;
        background-color: #ffffff;
        box-shadow: 0px 0px 26px #0000001f;
        min-height: 150px;
        display: flex;
        margin-bottom: 20px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        & > p {
            margin-bottom: 0 !important;
        }

        @media screen and (max-width: 786px) {
            padding: 20px;
        }

        .form-groups {
            width: 100%;
            display: flex;
            gap: 15px;
            padding: 0;

            @media screen and (max-width: 786px) {
                flex-wrap: wrap;
                gap: 0;
            }
        }

        .modal_input_grid_wrapper {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
        }

        .modal-heading-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .modal-heading-2 {
                margin-bottom: 0;
            }

            .copy-all-btn {
                font-size: 15px;
                border: 1px solid $COLOR_BG;
                padding: 6px 14px;
                display: flex;
                border-radius: 30px;
                color: $COLOR_BG !important;
                cursor: pointer;
                align-items: center;
                line-height: 17px;
                width: 275px;

                @media screen and (max-width: 576px) {
                    font-size: 13px;
                    line-height: 15px;
                }

                svg {
                    font-size: 15px;
                    color: $COLOR_BG;
                }

                &:hover {
                    background: $COLOR_BG;
                    color: #fff !important;

                    svg {
                        color: #fff;
                    }
                }
            }
        }

        .modal-heading-1,
        .modal-heading-2,
        .modal-heading-3,
        .modal-heading-4,
        .modal-heading-5,
        .modal-heading-6 {
            color: $DARK_BLUE;
            letter-spacing: 0;
            text-align: left;
            font-weight: 400;
            width: 100%;
            margin-bottom: 15px;
        }

        .modal-heading-1 {
            font-size: 34px;
            line-height: 40px;

            @media screen and (max-width: 1199px) {
                font-size: 30px;
            }
        }

        .modal-heading-2 {
            font-size: 28px;
            line-height: 34px;

            @media screen and (max-width: 1199px) {
                font-size: 24px;
            }
        }

        .modal-heading-3 {
            font-size: 22px;
            line-height: 28px;

            @media screen and (max-width: 1199px) {
                font-size: 20px;
            }
        }

        .modal-heading-4 {
            font-size: 18px;
            line-height: 24px;

            @media screen and (max-width: 1199px) {
                font-size: 16px;
            }
        }

        .modal-heading-5 {
            font-size: 16px;
            line-height: 22px;

            @media screen and (max-width: 1199px) {
                font-size: 14px;
            }
        }

        .modal-heading-6 {
            font-size: 14px;
            line-height: 20px;

            @media screen and (max-width: 1199px) {
                font-size: 13px;
            }
        }

        .form-input {
            width: 100%;

            .form-input-groups {
                padding: 0 0 15px;
            }
        }

        .modal-card-list {
            span {
                color: $COLOR_BG;
                letter-spacing: 0;
                text-align: left;
                font-size: 16px;
                padding-top: 30px;
                display: block;
                font-weight: 700;
            }

            ul {
                padding-left: 0;

                li.list {
                    position: relative;
                    color: $COLOR_BG;
                    list-style-type: none;
                    font-size: 16px;
                    padding-left: 15px;

                    &:before {
                        background-color: #ff4e00;
                        border-radius: 50%;
                        content: '';
                        display: inline-block;
                        height: 5px;
                        margin-right: 10px;
                        width: 5px;
                        position: absolute;
                        top: 11px;
                        left: 0;
                    }
                }
            }
        }
    }

    .modalScrollArea {
        max-height: 600px;
        overflow: auto;
        padding: 10px;
        width: 100%;

        @media screen and (max-width: 1480px) {
            max-height: 500px;
        }

        @media screen and (max-width: 1366px) {
            max-height: 400px;
        }
    }

    .modalCloseWrapper {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        .modalCloseBtn {
            opacity: 1;
            background-color: #fff;
            border-radius: 50%;
            height: 50px;
            width: 50px;
            min-width: 50px;
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg {
                font-size: 30px !important;
                color: #000;
            }
        }
    }
}

.inputField {
    width: 738px;
}

// SPACING
.mt-1 {
    margin-top: 10px;
}

//ICON
span.status_icon {
    width: 100%;
    height: 100%;
    display: flex;
}
.delete-icon {
    cursor: pointer;
    display: flex;
    width: fit-content;

    svg {
        color: #000;
    }

    &:hover {
        svg {
            color: red;
        }
    }
}

.delete-light-icon {
    cursor: pointer;

    &:hover {
        svg path {
            fill: red;
        }
    }
}

//pagination

.pagination {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 15px;

    @media screen and (max-width: 576px) {
        justify-content: center;
    }

    button {
        margin: 0 22px;
        border: 1px solid $COLOR_BG;
        background-color: #fff;
        color: $COLOR_BG;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        line-height: 14px;

        @media screen and (max-width: 576px) {
            margin: 0 15px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &:hover {
            background-color: $COLOR_BG;
            color: #fff;
        }

        &:disabled {
            cursor: default;
            opacity: 0.3;

            &:hover {
                background-color: transparent;
                color: $COLOR_BG;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }

    & > span {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        @media screen and (max-width: 576px) {
            gap: 2px;
        }

        span {
            color: #8f8fa9;
            padding: 0 5px;
            font-size: 13px;
        }

        strong {
            color: $COLOR_BLUE-SHADE_1;
            font-size: 13px;
        }
    }
}

.full-width-field {
    width: 100% !important;
    margin-bottom: 18px;

    .spotdif-field {
        width: 100% !important;
    }
}

.column-slide-right {
    .spotdif-field {
        width: 100% !important;

        input {
            padding: 4px 6px !important;
        }
    }
}

// EXPAND CARD
.expand-card {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
    display: flex !important;
    justify-content: center;
    width: 100%;
    padding: 35px;
    margin-bottom: 15px;

    @media screen and (max-width: 1366px) {
        padding: 20px;
    }

    @media screen and (max-width: 1199px) {
        padding: 15px;
    }

    .expand-card-top {
        display: flex;
        justify-content: space-between;
        align-items: end;
        margin-bottom: 20px;

        //
        &.creditamount {
            .spotdif-field {
                width: 60% !important;

                label {
                    display: none;
                }
            }
        }

        & > p {
            font-size: 15px;
            color: $COLOR_PRIMARY;
            align-items: center;
            font-weight: 400;
            padding-right: 20px;
            width: calc(40% - 20px);
            line-height: 19px;

            @media screen and (max-width: 1366px) {
                font-size: 14px;
            }
        }

        & > div {
            display: flex;
            align-items: center;
            width: 60%;
            justify-content: flex-end;

            span {
                color: $COLOR_PRIMARY;
            }

            svg {
                margin-left: 5px;
                color: $COLOR_PRIMARY;
            }
        }
    }
    .expand-card-middle,
    .expand-card-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        & > span {
            color: #344767;
            font-size: 15px;
            line-height: 18px;

            @media screen and (max-width: 1366px) {
                font-size: 14px;
                line-height: 16px;
            }
        }

        .badge {
            border-radius: 30px !important;
            width: auto !important;
            font-size: 14px;
            padding-left: 20px;
            padding-right: 20px;
        }

        & > p {
            font-size: 15px;
            color: Gray;
            align-items: center;
            font-weight: 400;
            padding-right: 20px;
            width: calc(40% - 20px);
            line-height: 19px;

            @media screen and (max-width: 1366px) {
                font-size: 14px;
            }
        }

        & > div,
        & > .expand-card-input {
            width: 60%;
            justify-content: flex-end;

            &.toggle-button {
                display: flex;
                justify-content: space-between;
                border: 1px solid #ffeded;
                // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                border-radius: 30px;
                // padding: 3px;
                background: #ffeded;
                margin: 10px 0px;
            }
        }

        .spotdif-field {
            padding-left: 0;
            width: 100% !important;

            .note-textarea {
                input {
                    text-align: left;
                    height: 22px;
                }

                fieldset {
                    border-radius: 5px;
                }
            }

            label {
                margin: 0;
            }

            input {
                text-align: right;
            }

            .input-adorement {
                span {
                    color: #000;
                    display: block;
                    font-size: 15px;
                }
            }
        }

        .autocomplete-field {
            .spotdif-field {
                input {
                    text-align: left;
                    padding-top: 2px;
                    padding-bottom: 2px;
                }
            }

            .MuiFormControl-root {
                border-radius: 30px;
            }
        }
    }

    .expand-card-bottom {
        margin-bottom: 0;
        margin-top: 10px;
    }

    .expand-card-btn-groups {
        display: flex;
        gap: 10px;

        @media screen and (max-width: 1199px) {
            flex-wrap: wrap;
        }

        input {
            min-width: inherit;

            @media screen and (max-width: 1199px) {
                width: 100% !important;
            }
        }
    }
}
.creditamount {
    label {
        display: none;
    }
    .expand-card-top {
        align-items: end;
    }
}

.input-adorement {
    span {
        color: #000;
        display: block;
        font-size: 15px;
    }
}

.expand-card-shadow-none {
    box-shadow: none !important;
    padding: 0;
}
.ml-2 {
    margin-left: 2rem;
}
.expand-left-box {
    .expand-heading-top {
        font-size: 16px;
        color: #000;
        margin-bottom: 15px;
    }

    .expand-details-field {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        align-items: center;

        & > p {
            font-size: 15px;
            color: Gray;
            align-items: center;
            font-weight: 400;
            padding-right: 20px;
            width: calc(40% - 20px);
            line-height: 19px;
        }

        & > .expand-field-value {
            display: flex;
            width: 60%;
            justify-content: flex-end;
            font-size: 15px;
            align-items: center;
            position: relative;

            .copy-content-icon {
                position: absolute;
                right: -28px;
                z-index: 1;
                top: 6px;
                width: 18px;
                height: 18px;
                cursor: pointer;
            }

            .spotdif-field {
                width: 90% !important;

                label {
                    margin-bottom: 0;
                }

                input {
                    text-align: right;
                    font-size: 15px;
                }
            }
        }
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .creditamount button {
        font-size: 12px;
        min-height: 32px;
        min-width: 121px;
        max-height: 0 !important;
    }
}

.text-bold-brandPanel + p {
    font-weight: 700;
}
