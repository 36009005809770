
          $font-path: "/home/runner/work/spotdif/spotdif/src/assets/fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
/*-- for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/*-- for Firefox */
input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
}
.branding-wrapper {
    background-color: #f4f4fa;
}
.features-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 15px 20px;
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        margin: 10px;
        gap: 10px;
    }
}

button.sticky-get-free-leads {
    display: none;
    border-radius: 50px;
    background: #ff4e00;
    border: none;
    padding: 12px 60px;
    align-items: center;
    color: #fff;
    font-weight: 500;
    position: fixed;
    z-index: 1000;
    bottom: 22px;
    left: 0;
    width: 88%;
    justify-content: center;
    right: 0;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
        display: flex;
    }
    span {
        margin-left: 10px;
    }
}
.login-footer-v2 {
    display: flex;
    font-size: 15px;
    justify-content: space-between;
    margin: 10px 20px;
    color: #344767;
    .login-footer-terms-box {
        font-size: 15px;
    }
}
