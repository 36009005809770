
          $font-path: "/home/runner/work/spotdif/spotdif/src/assets/fonts";
        
$COLOR_PRIMARY: #63adf2;
$COLOR_PRIMARY-SHADE_1: #ff4e02;
// $COLOR_PRIMARY-SHADE_1: #001F55;

// $COLOR_BG: #000032;
$COLOR_BG: #001f55;

$COLOR_BLUE-SHADE_1: #052473;
$COLOR_BLUE_INTRIGUE: #7e7e9a;

$COLOR_BLUE-SHADE_LIGHT: #f4f4fa;
$COLOR_BG_LIGHT: #e2e3ef;

$COLOR_BLACK-SHADE_ALPHA: rgba(0, 0, 0, 0.2);
$BLACK_OAK: #4e4e4e;

$COLOR_WHITE: #ffffff;
$COLOR_WHITE-SHADE_1: #f0f2f5;
$COLOR_WHITE_SOLID: #f4f4fa;
$COLOR_LABEL: #9fa1b3;

$COLOR_ERROR: #f44335;
$AEROSPACE_ORANGE: #ff4e00;

$COLOR_GREEN: #19cbb1;
$COLOR_GREEN-SHADE_1: #1de993;
$COLOR_GREEN_SHADE_2: #24c568;

$SUGAR_MILK: #fff8f4;
$LAVENDER_SYRUP: #e5e5f3;

$GRAY_BORDER: #e3e3ed;
$PROVOCATIVE: #4f4f7a;
$GRAY: rgb(122, 121, 143);
$GRAY-1: #79798f;
$CARD-WRAPPER-LIGHT: #e9e9f8;
$CARD-WRAPPER-DARK: #142f73;
$FONT_PRIMARY: 'Euclid circular a', sans-serif;

$COLOR_HEADING_V2: #ff5252;
$COLOR_BORDER_V2: #e5e5ec;
$BORDER_COLOR: #bfbfbf;

$ACTIVE_ROW: #fff2ec;

$HEADING_COLOR_1: #000030;

// NEW COLOR DESIGN

$DARK_BLUE: #000e36;

$NEW_TEXT_BLACK: #000000;
$NEW_TEXT_GREY: #f1f1f1;
$NEW_TEXT_DARK_GREY: #2B2B2B;
$CLOUD_COLOR: #F1FFE7;
$DANGER_COLOR: #ed404c;
$SPOTDIF_GREEN: #ACF564;
.app_tabs-nav.w-tab-menu {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 2rem;

    @media screen and (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 576px) {
        grid-template-columns: 1fr;
    }

    .app_tabs-link {
        align-items: center;
        background-color: #909090;
        border-radius: 0.6rem;
        color: #f4f4f4;
        display: flex;
        font-weight: 300;
        padding: 0.5rem 1.5rem 0.5rem 1.3rem;
        vertical-align: top;
        cursor: pointer;
        font-size: 16px;
        border: none;

        &.w--current {
            background-color: $DARK_BLUE;
            color: #fff;
        }

        .app_tab-icon {
            margin-right: 0.5rem;
        }
    }
}

.app_tab-layout {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: auto;

    .app_card {
        background-color: #fff;
        border-bottom-left-radius: 0.6rem;
        border-bottom-right-radius: 0.6rem;
        border-top-left-radius: 0.6rem;
        border-top-right-radius: 0.6rem;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
        padding: 3rem;

        @media screen and (max-width: 991px) {
            padding: 30px;
        }

        .w-form {
            margin: 0 0 15px;

            .modal_wrap {
                grid-column-gap: 16px;
                grid-row-gap: 16px;
                display: grid;
                grid-auto-columns: 1fr;
                grid-template-columns: 1fr;
                grid-template-rows: auto auto;

                .modal_title {
                    padding-bottom: 1.5rem;

                    .heading-style-card {
                        color: $COLOR_BG;
                        font-size: 2rem;
                        font-weight: 400;
                        line-height: 1.2;

                        @media screen and (max-width: 768px) {
                            font-size: 1.7rem;
                        }
                    }
                }

                .modal_col-3 {
                    grid-column-gap: 20px;
                    grid-row-gap: 16px;
                    display: grid;
                    grid-auto-columns: 1fr;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-template-rows: auto;

                    @media screen and (max-width: 991px) {
                        grid-template-columns: 1fr;
                    }
                    .spotdif-field {
                        width: 100% !important;
                    }
                }

                .modal_input-wrap {
                    & > .MuiFormControl-root {
                        width: 100%;
                    }

                    .Mui-focused {
                        fieldset {
                            border-color: $COLOR_PRIMARY !important;
                        }
                    }

                    label.Mui-focused {
                        color: $COLOR_PRIMARY !important;
                    }
                }
            }
        }
    }

    .button-group {
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
    }
}

.w-form-done,
.w-form-fail {
    margin-top: 20px;
    font-size: 18px;
    color: $COLOR_GREEN;
}

.w-form-fail {
    color: $COLOR_ERROR;
}

.button.is-secondary {
    background-color: transparent;
    border-color: #ff4e02;
    border-style: solid;
    color: #ff4e02;
}
